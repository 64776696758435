import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import reportGroupsReducer from "./features/reportGroups";
import contractorsReducer from "./features/contractors";
import professionsReducer from "./features/professions";
import contractsReducer from "./features/contracts";
import companiesReducer from "./features/companies";
import reportsReducer from "./features/reports";
import sessionReducer from "./features/session";
import banksReducer from "./features/banks";
export const store = configureStore({
    reducer: {
        reportGroups: reportGroupsReducer,
        professions: professionsReducer,
        contractors: contractorsReducer,
        contracts: contractsReducer,
        companies: companiesReducer,
        session: sessionReducer,
        reports: reportsReducer,
        banks: banksReducer,
    },
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware();
        if (process.env.NODE_ENV !== "production") {
            middlewares.push(logger);
        }
        return middlewares;
    },
});
