import { useSelector } from "react-redux";
import * as React from "react";
import cx from "clsx";
import { getSession } from "../../selectors";
import { Icons } from "../../components";
import { Link } from "react-router-dom";
export function ProfileButton(props) {
    var _a;
    const session = useSelector(getSession);
    const classes = cx("items-center justify-start", "cursor-pointer", "flex flex-row", "border-b", "px-3 h-12", "border-grey-200 dark:border-grey-800", "transition ease", "duration-300", "hover:border-white dark:hover:border-grey-700", "hover:bg-gray-200 dark:hover:bg-grey-800", props.className);
    return (React.createElement(Link, { to: "/dashboard/profile", className: classes },
        React.createElement("div", { className: "h-8 w-8 flex rounded-full items-center justify-center bg-grey-200 dark:bg-grey-800" },
            React.createElement(Icons.Users, { className: "h-4 w-4 dark:text-grey-50" })),
        React.createElement("div", { className: "ml-4 text-md font-medium text-grey-800 dark:text-grey-50" }, (_a = session.user) === null || _a === void 0 ? void 0 : _a.name)));
}
