import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { i18n } from "@lingui/core";
import { DetailsMap, Icons, Modal, PageMenu, Spinner } from "../../../components";
import { CompanyForm } from "../../../containers";
import * as selectors from "../../../selectors";
import { UploadSignature } from "./UploadSignature";
import { NotifyEmails } from "./NotifyEmails";
export function CompanyDetails() {
    const [formOpen, setFormOpen] = useState(false);
    const { id } = useParams();
    const company = useSelector((state) => selectors.getCompany(state, id));
    const onOpenModal = useCallback(() => setFormOpen(true), []);
    const modalProps = {
        onClose: () => setFormOpen(false),
        title: i18n._("sidebar__company"),
        name: "company_editor",
        open: formOpen,
    };
    const formProps = {
        onSaved: () => setFormOpen(false),
        company,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageMenu, { title: i18n._("sidebar__company"), actions: [
                {
                    icon: Icons.Edit,
                    title: i18n._("edit"),
                    onClick: onOpenModal,
                },
            ] }),
        !company ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
            React.createElement(Spinner, null))) : (React.createElement("div", { className: "flex flex-row" },
            React.createElement("div", { className: "w-1/2 border-r border-b border-grey-200 dark:border-grey-800" },
                React.createElement(DetailsMap, { keys: ["name", "requisites", "registration", "representative"], localize: { registration: true }, itemClassName: "px-7", source: company, name: "company" })),
            React.createElement("div", { className: "flex flex-col w-1/2" },
                React.createElement(UploadSignature, null),
                React.createElement(NotifyEmails, null)))),
        formOpen && (React.createElement(Modal, Object.assign({}, modalProps),
            React.createElement("div", { className: "flex flex-col px-5 py-2" }, formOpen && React.createElement(CompanyForm, Object.assign({}, formProps)))))));
}
