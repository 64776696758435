import { request } from "./common";
export function getReportsByContractor(companyId, contractorId, token) {
    return request(`/company/${companyId}/contractor/${contractorId}/report`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function getReport(companyId, id, token) {
    return request(`/company/${companyId}/report/${id}`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function deleteReport(companyId, id, token) {
    return request(`/company/${companyId}/report/${id}`, "DELETE", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function generateAndSendDocuments(companyId, id, token) {
    return request(`/company/${companyId}/report/${id}/process-all-documents`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function updateReport(companyId, id, body, token) {
    return request(`/company/${companyId}/report/${id}`, "PATCH", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function createReport(companyId, body, token) {
    return request(`/company/${companyId}/report`, "POST", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
