var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n } from "@lingui/core";
import * as selectors from "../../selectors";
import * as toast from "../../containers/Toast/actions";
import * as api from "../../api";
export const setContract = createAction("contracts/set/contract");
export const setContracts = createAction("contracts/set");
export const unsetContractsByContractor = createAction("contracts/unset/byContractor");
export const unsetContract = createAction("contracts/unset/contract");
export const setInitialized = createAction("contracts/initialized");
export const getContracts = createAsyncThunk("contracts/list", ({ companyId, contractorId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getContracts(companyId, contractorId, token);
            dispatch(setContracts({
                companyId: company.id,
                contracts: data,
                contractorId,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
    dispatch(setInitialized(true));
}));
export const getContract = createAsyncThunk("contracts/getOne", ({ id, companyId, contractorId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getContract(companyId, contractorId, id, token);
            dispatch(setContract({
                companyId: company.id,
                contract: data,
                contractorId,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
}));
export const createContract = createAsyncThunk("contracts/createOne", ({ contract, companyId, contractorId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.createContract(companyId, contractorId, contract, token);
            dispatch(setContract({
                companyId: company.id,
                contract: data,
                contractorId,
            }));
            toast.success(i18n._("toast_create_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_create_failed"));
        }
    }
}));
export const deleteContract = createAsyncThunk("contract/deleteContract", ({ id, companyId, contractorId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            yield api.deleteContract(companyId, contractorId, id, token);
            dispatch(unsetContract(id));
            toast.success(i18n._("toast_delete_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_delete_failed"));
        }
    }
}));
export const updateContract = createAsyncThunk("contracts/updateOne", ({ id, companyId, contractorId, contract }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.updateContract(companyId, contractorId, id, contract, token);
            dispatch(setContract({
                companyId: company.id,
                contract: data,
                contractorId,
            }));
            toast.success(i18n._("toast_update_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_update_failed"));
        }
    }
}));
