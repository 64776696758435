var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n } from "@lingui/core";
import * as selectors from "../../selectors";
import * as toast from "../../containers/Toast/actions";
import * as api from "../../api";
export const setInitialized = createAction("companies/initialized");
export const setCompany = createAction("companies/set/company");
export const setCompanies = createAction("companies/set");
export const getCompanies = createAsyncThunk("companies/list", (payload, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            const { data } = yield api.getCompanies(token);
            dispatch(setCompanies(data));
        }
        catch (error) {
            // @TODO
        }
    }
    dispatch(setInitialized(true));
}));
export const getCompany = createAsyncThunk("companies/getOne", (id, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            const { data } = yield api.getCompany(id, token);
            dispatch(setCompany(data));
        }
        catch (error) {
            // @TODO
        }
    }
}));
export const createCompany = createAsyncThunk("companies/createOne", (company, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            const { data } = yield api.createCompany(company, token);
            dispatch(setCompany(data));
            toast.success(i18n._("toast_create_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_create_failed"));
        }
    }
}));
export const updateCompany = createAsyncThunk("companies/updateOne", ({ id, company }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            const { data } = yield api.updateCompany(id, company, token);
            dispatch(setCompany(data));
            toast.success(i18n._("toast_update_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_update_failed"));
        }
    }
}));
export const uploadCompanySignature = createAsyncThunk("companies/uploadSignature", ({ id, file, onProgress }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            const { data: { data }, } = yield api.uploadSignature(id, file, token, onProgress);
            dispatch(setCompany(data));
            toast.success(i18n._("toast_update_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_update_failed"));
        }
    }
}));
