import { useSelector } from "react-redux";
import { i18n } from "@lingui/core";
import React from "react";
import { PageMenu, Spinner } from "../../components";
import * as selectors from "../../selectors";
import { UpdatePasswordForm } from "./ChangePassword";
import { ProfileForm } from "./UpdateProfile";
export function Profile() {
    const session = useSelector(selectors.getSession);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageMenu, { title: i18n._("profile_title") }),
        !session.active ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
            React.createElement(Spinner, null))) : (React.createElement("div", { className: "flex flex-row px-2 border-b border-b-grey-200 dark:border-b-grey-800" },
            React.createElement(ProfileForm, { className: "flex-1 px-6 border-r border-r-grey-200 dark:border-r-grey-800" }),
            React.createElement(UpdatePasswordForm, { className: "flex-1 px-6" })))));
}
