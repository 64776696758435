var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { i18n } from "@lingui/core";
import dayjs from "dayjs";
import cx from "clsx";
import { Button, DownloadButton, Icons, Spinner } from "../../../components";
import { generateAndSendDocuments } from "../../../features/reports";
import * as selectors from "../../../selectors";
export function Report({ id, actions, className, short, onEdit, onDelete }) {
    const [generateLoading, setGenerateLoading] = useState(false);
    const dispatch = useDispatch();
    const { id: companyId } = useParams();
    const report = useSelector((state) => selectors.getReport(state, id));
    if (!report) {
        return null;
    }
    const contractor = useSelector((state) => selectors.getContractor(state, report === null || report === void 0 ? void 0 : report.contractor));
    const onGenerateDocs = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setGenerateLoading(true);
        try {
            yield dispatch(generateAndSendDocuments({ id, companyId })).unwrap();
        }
        finally {
            setGenerateLoading(false);
        }
    }), []);
    return (React.createElement("div", { className: cx("flex flex-row items-center relative px-4 py-2 w-full border-b border-grey-100 dark:border-grey-800", className) },
        React.createElement("div", { className: cx("flex flex-col", {
                "w-24": !short,
                "w-40": short,
            }) },
            React.createElement("span", { className: "text-md font-semibold text-grey-900 dark:text-grey-50" }, dayjs(report.created_date).format("DD.MM.YYYY")),
            short && (React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-300" },
                dayjs(report.range.start).format("DD.MM.YYYY"),
                " - ",
                dayjs(report.range.end).format("DD.MM.YYYY")))),
        !short && (React.createElement("div", { className: "flex flex-col flex-1" },
            React.createElement("span", { className: "text-sm font-medium text-grey-900 dark:text-grey-50" },
                React.createElement("span", { className: "font-semibold" },
                    i18n._("contractor"),
                    ":"),
                " ", contractor === null || contractor === void 0 ? void 0 :
                contractor.firstname,
                " ", contractor === null || contractor === void 0 ? void 0 :
                contractor.surname,
                " ", contractor === null || contractor === void 0 ? void 0 :
                contractor.patronymic),
            React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-300" },
                dayjs(report.range.start).format("DD.MM.YYYY"),
                " - ",
                dayjs(report.range.end).format("DD.MM.YYYY")))),
        React.createElement("div", { className: "flex flex-col flex-1" },
            React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-300" },
                React.createElement("span", { className: "font-semibold text-grey-900 dark:text-grey-50" }, i18n._("contract__salary")),
                ":",
                " ",
                report.salary.amount / 100,
                " ",
                report.salary.currency,
                " per ",
                i18n._(`contract__salary__${report.salary.type}`)),
            React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-300" },
                React.createElement("span", { className: "font-semibold text-grey-900 dark:text-grey-50" }, i18n._("contract__salary__amount")),
                ":",
                " ",
                report.summary.amount / 100,
                " ",
                report.summary.currency,
                " for ",
                report.summary.hours,
                " ",
                i18n._("contract__salary__hour"))),
        actions && (React.createElement(React.Fragment, null,
            report.completion_certificate && (React.createElement(DownloadButton, { filename: report.completion_certificate, src: `${process.env.API_HOST}/api/v1/company/${companyId}/report/${id}/certificate/${report.completion_certificate}`, color: "transparent", className: "p-2" },
                React.createElement(Icons.Download, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-200" }),
                " ",
                i18n._("report_cert"))),
            report.completion_invoice && (React.createElement(DownloadButton, { filename: report.completion_invoice, src: `${process.env.API_HOST}/api/v1/company/${companyId}/report/${id}/invoice/${report.completion_invoice}`, color: "transparent", className: "p-2" },
                React.createElement(Icons.Download, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-200" }),
                " ",
                i18n._("report_invoice"))),
            React.createElement(Button, { color: "transparent", className: "p-2", onClick: onGenerateDocs }, generateLoading ? React.createElement(Spinner, { size: "small" }) : React.createElement(Icons.Mail, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-200" })))),
        onDelete && (React.createElement(Button, { color: "transparent", className: "p-2 mr-4", onClick: () => onDelete(report) },
            React.createElement(Icons.Delete, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-200" }))),
        onEdit && (React.createElement(Button, { color: "transparent", className: "p-2", onClick: () => onEdit(report) },
            React.createElement(Icons.Edit, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-200" })))));
}
