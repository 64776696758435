import { createSlice } from "@reduxjs/toolkit";
import { logOut, setInitialized, setProfile, setToken, signIn } from "./actions";
export * from "./actions";
// Initialize base theme
function updateTheme() {
    if (localStorage.getItem("color-theme") === "dark") {
        document.documentElement.classList.add("dark");
    }
    else {
        document.documentElement.classList.remove("dark");
    }
}
updateTheme();
const initialState = {
    darkTheme: localStorage.getItem("color-theme") === "dark",
    initialized: false,
    active: false,
};
const extraReducers = {
    [setProfile.type]: (state, { payload }) => {
        state.user = payload;
    },
    [setToken.type]: (state, { payload }) => {
        if (payload) {
            state.token = payload;
            state.active = true;
        }
        else {
            state.initialized = true;
            state.token = undefined;
            state.active = false;
        }
    },
    [logOut.type]: (state) => {
        localStorage.removeItem("token");
        state.initialized = true;
        state.token = undefined;
        state.user = undefined;
        state.active = false;
    },
    [setInitialized.type]: (state, { payload }) => {
        state.initialized = payload;
    },
    [signIn.rejected.type]: (state, action) => {
        // @NOTHING
    },
};
export const sessionSlice = createSlice({
    name: "session",
    initialState,
    extraReducers,
    reducers: {
        toggleDark(state) {
            localStorage.setItem("color-theme", state.darkTheme ? "light" : "dark");
            state.darkTheme = !state.darkTheme;
            updateTheme();
        },
    },
});
// Action creators are generated for each case reducer function
export const { toggleDark } = sessionSlice.actions;
export default sessionSlice.reducer;
