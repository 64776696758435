import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { i18n } from "@lingui/core";
import { ProfileButton } from "../ProfileButton";
import { logOut } from "../../features/session";
import { Item } from "../../components/List";
import { Icons } from "../../components";
const ITEMS_DASHBOARD = () => [
    {
        title: "sidebar__companies",
        link: "/dashboard",
        icon: Icons.Collection,
    },
    {
        title: "sidebar__settings",
        link: "/dashboard/settings",
        icon: Icons.Settings,
    },
];
const ITEMS_COMPANY = (companyId) => [
    {
        title: "sidebar__company",
        link: `/company/${companyId}`,
        icon: Icons.Home,
    },
    {
        title: "sidebar__bank_detail",
        link: `/company/${companyId}/banks`,
        icon: Icons.Briefcase,
    },
    {
        title: "sidebar__professions",
        link: `/company/${companyId}/professions`,
        icon: Icons.Academic,
    },
    {
        title: "sidebar__contractors",
        link: `/company/${companyId}/contractors`,
        icon: Icons.Users,
    },
    {
        title: "sidebar__report_groups",
        link: `/company/${companyId}/report-groups`,
        icon: Icons.Document,
    },
];
function List(props) {
    // prettier-ignore
    const items = useMemo(() => (props.companyId
        ? ITEMS_COMPANY(props.companyId)
        : ITEMS_DASHBOARD()), [props.companyId]);
    // prettier-ignore
    const links = items.map(({ title, link, icon }) => (React.createElement(Item, { key: title, title: i18n._(title), to: link, icon: icon })));
    return React.createElement("ul", { className: "space-y-2" }, links);
}
export function Sidebar(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onLogOut = useCallback(() => {
        dispatch(logOut());
        navigate("/signin", { replace: true });
    }, []);
    return (React.createElement("aside", { className: "sidebar w-64 border-r bg-white dark:bg-grey-900 border-grey-200 dark:border-grey-800" },
        React.createElement(ProfileButton, { className: "mb-3" }),
        React.createElement("div", { className: "overflow-y-auto py-4 px-2" },
            React.createElement(List, Object.assign({}, props)),
            React.createElement("ul", { className: "mt-5" },
                React.createElement(Item, { title: i18n._("sidebar__logout"), onClick: onLogOut, icon: Icons.Close })))));
}
