var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { generateAndSendDocuments } from "../../../features/reports";
import { deleteReportGroup } from "../../../features/reportGroups";
import { Button, Icons, Spinner } from "../../../components";
import { Report as ReportItem } from "../Reports";
export function RowGroup({ group, reports }) {
    const [progress, setProgress] = useState();
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const currentProgress = progress && progress / group.reports.length;
    const onDenerateDocuments = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setProgress(1);
        const tasks = reports.map(report => (() => __awaiter(this, void 0, void 0, function* () {
            try {
                yield dispatch(generateAndSendDocuments({ id: report.id, companyId: group.company })).unwrap();
            }
            finally {
                const currentCompletedTasks = progress || 0;
                setProgress(currentCompletedTasks + 1);
            }
        }))());
        yield Promise.all(tasks);
        setProgress();
    }), [group, progress]);
    const onDeleteGroup = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        dispatch(deleteReportGroup({
            companyId: group.company,
            id: group.id,
        }));
    }), [group]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-row items-center relative px-4 py-2 w-full border-b cursor-pointer border-grey-100 dark:border-grey-800", onClick: () => setExpanded(!expanded) },
            React.createElement("div", { className: "flex flex-col w-20" },
                React.createElement("span", { className: "text-md font-semibold text-grey-900 dark:text-grey-50" },
                    "\u2116 ",
                    group.id)),
            React.createElement("div", { className: "flex flex-col flex-1" },
                React.createElement("span", { className: "text-sm font-medium text-grey-900 dark:text-grey-50" },
                    React.createElement("span", { className: "font-semibold" }, "Reports:"),
                    " ",
                    group.reports.length),
                React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-300" },
                    dayjs(group.range.start).format("DD.MM.YYYY"),
                    " - ",
                    dayjs(group.range.end).format("DD.MM.YYYY"))),
            React.createElement("div", { className: "flex flex-row items-center justify-end" },
                currentProgress && (React.createElement("div", { className: "w-32 rounded-full overflow-hidden bg-grey-200 dark:bg-grey-600" },
                    React.createElement("div", { className: "text-xs font-medium text-center p-0.5 leading-none rounded-full text-blue-100 bg-blue-600", style: { width: `${currentProgress}%` } },
                        currentProgress,
                        "%"))),
                React.createElement(Button, { color: "transparent", className: "ml-5 p-2", onClick: onDeleteGroup },
                    React.createElement(Icons.Delete, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-200" })),
                React.createElement(Button, { color: "transparent", className: "ml-5 p-2", onClick: onDenerateDocuments }, currentProgress ? (React.createElement(Spinner, { size: "small" })) : (React.createElement(Icons.Mail, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-200" }))))),
        expanded && (React.createElement("div", { className: "flex flex-1 flex-col mx-10 my-2 border rounded border-grey-100 dark:border-grey-800" }, group.reports.map(reportId => (React.createElement(ReportItem, { key: group.id + reportId, id: reportId, actions: true })))))));
}
