var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors";
export function Image(_a) {
    var { src } = _a, props = __rest(_a, ["src"]);
    const [source, setSource] = useState();
    const session = useSelector(selectors.getSession);
    const download = useCallback(() => {
        fetch(src, {
            headers: { Authorization: `Bearer ${session.token}` },
            method: "GET",
        })
            .then(response => {
            if (response.status != 200) {
                throw new Error("Bad server response");
            }
            return response.blob();
        })
            .then(blob => {
            const base64 = window.URL.createObjectURL(blob);
            setSource(base64);
        });
    }, [src, session]);
    useEffect(() => {
        if (session.token && src) {
            download();
        }
    }, [download, session, src]);
    return React.createElement("img", Object.assign({}, props, { src: source }));
}
