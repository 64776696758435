import { request } from "./common";
export function getContractors(companyId, token) {
    return request(`/company/${companyId}/contractor`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function getContractor(companyId, id, token) {
    return request(`/company/${companyId}/contractor/${id}`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function updateContractor(companyId, id, body, token) {
    return request(`/company/${companyId}/contractor/${id}`, "PATCH", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function createContractor(companyId, body, token) {
    return request(`/company/${companyId}/contractor`, "POST", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function deleteContractor(companyId, id, token) {
    return request(`/company/${companyId}/contractor/${id}`, "DELETE", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
