var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { i18n } from "@lingui/core";
import { DetailsMap, Icons, Modal, PageMenu, Spinner } from "../../../components";
import { getContractor } from "../../../features/contractors";
import { ContractorForm } from "../../../containers";
import { ContractorContracts } from "./Contracts";
import * as selectors from "../../../selectors";
import { ContractorReports } from "./Reports";
export function CompanyContractor() {
    const [contractorEditable, setContractorEditable] = useState();
    const [contractorFormOpen, setContractorFormOpen] = useState(false);
    const [contractorLoading, setContractorLoading] = useState(true);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const { id: companyId, contractorId } = useParams();
    // prettier-ignore
    const contractor = useSelector((state) => selectors.getContractor(state, contractorId));
    const company = useSelector((state) => selectors.getCompany(state, companyId));
    const getContractorItem = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active) {
            setContractorLoading(true);
            try {
                yield dispatch(getContractor({ companyId, id: contractorId })).unwrap();
            }
            finally {
                setContractorLoading(false);
            }
        }
    }), [companyId, contractorId, session]);
    const onOpenContractorModal = useCallback(() => {
        setContractorEditable(contractor);
        setContractorFormOpen(true);
    }, [contractor, contractorId]);
    useEffect(() => {
        getContractorItem();
    }, [companyId, contractorId, session]);
    const modalContractorProps = {
        title: i18n._("sidebar__contractors"),
        name: "contractors_editor",
        open: contractorFormOpen,
        onClose: () => {
            setContractorEditable(undefined);
            setContractorFormOpen(false);
        },
    };
    const formContractorProps = {
        onSaved: () => setContractorFormOpen(false),
        contractor: contractorEditable,
        companyId: company === null || company === void 0 ? void 0 : company.id,
        contractorId,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageMenu, { title: contractorLoading ? "..." : contractor ? `${contractor.firstname} ${contractor.surname}` : "404", actions: [
                {
                    icon: Icons.Edit,
                    title: i18n._("edit"),
                    onClick: onOpenContractorModal,
                },
            ] }),
        !company ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
            React.createElement(Spinner, null))) : (React.createElement("div", { className: "flex flex-row" },
            React.createElement("div", { className: "w-1/3 flex flex-col mr-4 rounded" }, contractorLoading ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
                React.createElement(Spinner, null))) : contractor ? (React.createElement(DetailsMap, { className: "rounded-br flex-1 border-r border-b border-grey-50 dark:border-grey-800 bg-white dark:bg-grey-900", localize: { citizenship: "company" }, source: contractor || {}, itemClassName: "px-5", name: "contractor", keys: [
                    "firstname",
                    "surname",
                    "patronymic",
                    "citizenship",
                    "enabled",
                    "email",
                    "phone",
                    "bank_detail",
                    "address",
                ] })) : (React.createElement("div", { className: "flex items-center justify-center p-10" },
                React.createElement("span", { className: "font-semibold text-xl text-grey-600 dark:text-grey-400" }, "404")))),
            React.createElement("div", { className: "flex flex-col flex-1 mt-5" },
                React.createElement(ContractorContracts, { companyId: company === null || company === void 0 ? void 0 : company.id, contractorId: contractor === null || contractor === void 0 ? void 0 : contractor.id }),
                React.createElement(ContractorReports, { className: "mt-5", companyId: company === null || company === void 0 ? void 0 : company.id, contractorId: contractor === null || contractor === void 0 ? void 0 : contractor.id })))),
        contractorFormOpen && (React.createElement(Modal, Object.assign({}, modalContractorProps),
            React.createElement("div", { className: "flex flex-col px-5 py-2" }, contractorFormOpen && React.createElement(ContractorForm, Object.assign({}, formContractorProps)))))));
}
