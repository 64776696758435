var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n } from "@lingui/core";
import * as selectors from "../../selectors";
import * as toast from "../../containers/Toast/actions";
import * as api from "../../api";
export const unsetReportsByContractor = createAction("reports/unset/byContractor");
export const unsetReport = createAction("reports/unset/report");
export const setInitialized = createAction("reports/initialized");
export const setReport = createAction("reports/set/report");
export const setReports = createAction("reports/set");
export const format = (_a) => {
    var { sender_bank, contractor, contract, company, group } = _a, rest = __rest(_a, ["sender_bank", "contractor", "contract", "company", "group"]);
    return (Object.assign(Object.assign({}, rest), { sender_bank: sender_bank === null || sender_bank === void 0 ? void 0 : sender_bank.id, contractor: contractor === null || contractor === void 0 ? void 0 : contractor.id, contract: contract === null || contract === void 0 ? void 0 : contract.id, company: company === null || company === void 0 ? void 0 : company.id, group: group === null || group === void 0 ? void 0 : group.id }));
};
export const getReports = createAsyncThunk("reports/list", ({ companyId, contractorId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getReportsByContractor(companyId, contractorId, token);
            const reports = data.map(format);
            dispatch(setReports({
                companyId: company.id,
                reports,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
    dispatch(setInitialized(true));
}));
export const getReport = createAsyncThunk("reports/getOne", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getReport(companyId, id, token);
            const report = format(data);
            dispatch(setReport({
                contractorId: (_a = data === null || data === void 0 ? void 0 : data.contractor) === null || _a === void 0 ? void 0 : _a.id,
                companyId: company.id,
                report,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
}));
export const deleteReport = createAsyncThunk("reports/deleteReport", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            yield api.deleteReport(companyId, id, token);
            dispatch(unsetReport(id));
            toast.success(i18n._("toast_delete_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_delete_failed"));
        }
    }
}));
export const generateAndSendDocuments = createAsyncThunk("reports/generateAndSendDocuments", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.generateAndSendDocuments(companyId, id, token);
            const report = format(data);
            dispatch(setReport({
                contractorId: (_b = data === null || data === void 0 ? void 0 : data.contractor) === null || _b === void 0 ? void 0 : _b.id,
                companyId: company.id,
                report,
            }));
            toast.success(i18n._("toast_generate_docs_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_generate_docs_failed"));
        }
    }
}));
export const createReport = createAsyncThunk("reports/createOne", ({ options, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.createReport(companyId, options, token);
            const report = format(data);
            dispatch(setReport({
                contractorId: (_c = data === null || data === void 0 ? void 0 : data.contractor) === null || _c === void 0 ? void 0 : _c.id,
                companyId: company.id,
                report,
            }));
            toast.success(i18n._("toast_create_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_create_failed"));
        }
    }
}));
export const updateReport = createAsyncThunk("reports/updateOne", ({ id, companyId, options }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.updateReport(companyId, id, options, token);
            const report = format(data);
            dispatch(setReport({
                contractorId: (_d = data === null || data === void 0 ? void 0 : data.contractor) === null || _d === void 0 ? void 0 : _d.id,
                companyId: company.id,
                report,
            }));
            toast.success(i18n._("toast_update_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_update_failed"));
        }
    }
}));
