import toast from "react-hot-toast";
export function success(message) {
    toast(message, {
        position: "bottom-center",
        duration: 2500,
        // Styling
        className: "",
        style: {
            borderBottom: "4px solid #4CAF50",
            padding: "12px 20px",
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: 18,
        },
        // Custom Icon
        icon: "🤗",
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
}
export function error(message) {
    toast(message, {
        position: "bottom-center",
        duration: 2500,
        // Styling
        style: {
            borderBottom: "4px solid #F44336",
            padding: "12px 20px",
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: 18,
        },
        className: "",
        // Custom Icon
        icon: "😱",
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
}
