import { request } from "./common";
export function getBanks(companyId, token) {
    return request(`/company/${companyId}/bank`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function getBank(companyId, id, token) {
    return request(`/company/${companyId}/bank/${id}`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function updateBank(companyId, id, body, token) {
    return request(`/company/${companyId}/bank/${id}`, "PATCH", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function createBank(companyId, body, token) {
    return request(`/company/${companyId}/bank`, "POST", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function deleteBank(companyId, id, token) {
    return request(`/company/${companyId}/bank/${id}`, "DELETE", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
