import React from "react";
import cx from "clsx";
import { Button } from "../Button";
export function ActionMessage({ title, action, onClick, className, icon: Icon }) {
    return (React.createElement("div", { className: cx("flex flex-col items-center justify-center py-8 rounded border border-grey-200 bg-white dark:border-gray-800 dark:bg-grey-800", className) },
        React.createElement("div", { className: "font-medium text-grey-900 dark:text-grey-50 text-xl" }, title),
        React.createElement(Button, { className: "mt-5", onClick: onClick },
            Icon && React.createElement(Icon, { className: "h-4 w-4 mr-1" }),
            action)));
}
