var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { i18n } from "@lingui/core";
import * as Yup from "yup";
import cx from "clsx";
import { createContractor, updateContractor } from "../../../features/contractors";
import { Alert, Button, Spinner, Toggle } from "../../../components";
import { SelectProfession } from "./SelectProfession";
import { Country } from "../../../models";
import { FieldInput, FieldSelect } from "../Builder";
const schema = Yup.object({
    firstname: Yup.string().required(),
    surname: Yup.string().required(),
    patronymic: Yup.string().optional(),
    email: Yup.string().email().required(),
    phone: Yup.string().optional(),
    citizenship: Yup.mixed().oneOf(Object.values(Country)).required(),
    enabled: Yup.mixed().oneOf([true, false]).required(),
    bank_detail: Yup.string().required(),
    address: Yup.string().required(),
    profession: Yup.number().integer().required(),
}).required();
export function ContractorForm({ contractor, companyId, onSaved }) {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: Object.assign({}, contractor && {
            patronymic: contractor.patronymic,
            firstname: contractor.firstname,
            surname: contractor.surname,
            email: contractor.email,
            citizenship: contractor.citizenship,
            bank_detail: contractor.bank_detail,
            address: contractor.address,
            phone: contractor.phone,
            enabled: contractor.enabled,
            profession: contractor.profession,
        })
    });
    const onSubmit = useCallback((form) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            // prettier-ignore
            const action = contractor
                ? updateContractor({ companyId, id: contractor.id, contractor: form })
                : createContractor({ companyId, contractor: form });
            yield dispatch(action).unwrap();
            onSaved === null || onSaved === void 0 ? void 0 : onSaved(form);
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2500);
        }
        finally {
            setLoading(false);
        }
    }), []);
    // prettier-ignore
    return (React.createElement("form", { className: "form relative flex flex-col items-center", onSubmit: handleSubmit(onSubmit) },
        loading && (React.createElement("div", { className: "z-50 flex items-center justify-center absolute top-0 right-0 left-0 bottom-0", style: { background: "rgba(255,255,255, 0.3)" } },
            React.createElement(Spinner, null))),
        React.createElement("div", { className: cx("fields flex flex-col w-full", { "blur-sm": loading }) },
            React.createElement(FieldInput, { placeholder: "contractor__firstname", error: errors.firstname, validator: register, className: "mt-4", name: "firstname" }),
            React.createElement(FieldInput, { placeholder: "contractor__surname", error: errors.surname, validator: register, className: "mt-4", name: "surname" }),
            React.createElement(FieldInput, { placeholder: "contractor__patronymic", error: errors.patronymic, validator: register, className: "mt-4", name: "patronymic" }),
            React.createElement(FieldInput, { placeholder: "contractor__email", error: errors.email, validator: register, className: "mt-4", name: "email" }),
            React.createElement(FieldInput, { placeholder: "contractor__phone", error: errors.phone, validator: register, className: "mt-4", name: "phone" }),
            React.createElement(FieldInput, { placeholder: "contractor__bank_detail", error: errors.bank_detail, validator: register, className: "mt-4", name: "bank_detail", isArea: true }),
            React.createElement(FieldInput, { placeholder: "contractor__address", error: errors.address, validator: register, className: "mt-4", name: "address", isArea: true }),
            React.createElement(FieldSelect, { placeholder: "contractor__citizenship", error: errors.citizenship, validator: register, name: "citizenship", className: "mt-4" },
                React.createElement("option", { value: "kazakhstan" }, i18n._("company__kazakhstan")),
                React.createElement("option", { value: "serbia" }, i18n._("company__serbia")),
                React.createElement("option", { value: "slovenia" }, i18n._("company__slovenia")),
                React.createElement("option", { value: "belarus" }, i18n._("company__belarus")),
                React.createElement("option", { value: "germany" }, i18n._("company__germany")),
                React.createElement("option", { value: "russia" }, i18n._("company__russia")),
                React.createElement("option", { value: "usa" }, i18n._("company__usa"))),
            React.createElement(SelectProfession, { placeholder: i18n._("contractor__profession"), error: errors.profession, validator: register, name: "profession", className: "mt-4" }),
            React.createElement("div", { className: "flex flex-col mt-4" },
                React.createElement(Toggle, { label: i18n._("contractor__enabled"), error: !!errors.enabled, validator: register, name: "enabled" }))),
        error && (React.createElement(Alert, { fill: true, type: "error", className: "mt-5" }, i18n._(error))),
        React.createElement(Button, { color: error ? "red" : "blue", type: "submit", className: cx("mt-4", { "blur-sm": loading }) }, i18n._(contractor ? "contractors_update_action" : "contractors_create_action"))));
}
