import { request } from "./common";
export function getContracts(companyId, contractorId, token) {
    return request(`/company/${companyId}/contractor/${contractorId}/contract`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function getContract(companyId, contractorId, id, token) {
    return request(`/company/${companyId}/contractor/${contractorId}/contract/${id}`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function deleteContract(companyId, contractorId, id, token) {
    return request(`/company/${companyId}/contractor/${contractorId}/contract/${id}`, "DELETE", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function updateContract(companyId, contractorId, id, body, token) {
    return request(`/company/${companyId}/contractor/${contractorId}/contract/${id}`, "PATCH", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function createContract(companyId, contractorId, body, token) {
    return request(`/company/${companyId}/contractor/${contractorId}/contract`, "POST", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
