var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { i18n } from "@lingui/core";
import { ActionMessage, Icons, Modal, PageMenu, Spinner } from "../../../components";
import { deleteProfession, getProfessions } from "../../../features/professions";
import { ProfessionForm } from "../../../containers";
import * as selectors from "../../../selectors";
import { ProfessionCard } from "./Card";
export function CompanyProfession() {
    const [editable, setEditable] = useState();
    const [formOpen, setFormOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const { id } = useParams();
    const professionIds = useSelector((state) => selectors.getProfessionIdsByCompany(state, id));
    const company = useSelector((state) => selectors.getCompany(state, id));
    const professions = useSelector(selectors.getProfessions);
    const getProfessionList = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active) {
            setLoading(true);
            try {
                yield dispatch(getProfessions(id)).unwrap();
            }
            finally {
                setLoading(false);
            }
        }
    }), [id, session]);
    const onEditProfession = useCallback((profession) => {
        setEditable(profession);
        setFormOpen(true);
    }, [professions]);
    const onDeleteProfession = useCallback((profession) => {
        dispatch(deleteProfession({ id: profession.id, companyId: company.id }));
    }, [professions]);
    const onOpenModal = useCallback(() => setFormOpen(true), []);
    // prettier-ignore
    useEffect(() => { getProfessionList(); }, [id, session]);
    // prettier-ignore
    const items = professionIds && professionIds.map((id, index) => (React.createElement(ProfessionCard, Object.assign({ onDelete: onDeleteProfession, onEdit: onEditProfession }, professions[id], { key: id + index }))));
    const modalProps = {
        title: i18n._("sidebar__professions"),
        name: "professions_editor",
        open: formOpen,
        onClose: () => {
            setEditable(undefined);
            setFormOpen(false);
        },
    };
    const formProps = {
        onSaved: () => setFormOpen(false),
        companyId: company === null || company === void 0 ? void 0 : company.id,
        profession: editable,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageMenu, { title: i18n._("sidebar__professions"), actions: [
                {
                    icon: Icons.Plus,
                    title: i18n._("add"),
                    onClick: onOpenModal,
                },
            ] }),
        loading || !company ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
            React.createElement(Spinner, null))) : !(professionIds === null || professionIds === void 0 ? void 0 : professionIds.length) ? (React.createElement(ActionMessage, { title: i18n._("professions_empty_title"), action: i18n._("companies_empty_action"), className: "mx-20 mt-10", onClick: onOpenModal, icon: Icons.Plus })) : (React.createElement("div", { className: "flex flex-row flex-wrap mt-10 mx-8" }, items)),
        formOpen && (React.createElement(Modal, Object.assign({}, modalProps),
            React.createElement("div", { className: "flex flex-col px-5 py-2" }, formOpen && React.createElement(ProfessionForm, Object.assign({}, formProps)))))));
}
