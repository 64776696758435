import React from "react";
import cx from "clsx";
import { Input, Select } from "../../../components";
import { i18n } from "@lingui/core";
export function FieldInput({ inputClassName, placeholder, className, validator, isArea, error, name, type, }) {
    const inputProps = {
        placeholder: i18n._(placeholder),
        className: inputClassName,
        error: !!error,
        validator,
        isArea,
        name,
        type,
    };
    // prettier-ignore
    return (React.createElement("div", { className: cx("flex flex-col", className) },
        React.createElement(Input, Object.assign({}, inputProps)),
        !!error && (React.createElement("span", { className: "text-xs mt-1 text-medium text-red-600" }, i18n._(`error__${error.type}`)))));
}
export function FieldSelect({ placeholder, validator, name, error, className, children }) {
    const inputProps = {
        placeholder: i18n._(placeholder),
        error: !!error,
        validator,
        name,
    };
    // prettier-ignore
    return (React.createElement("div", { className: cx("flex flex-col", className) },
        React.createElement(Select, Object.assign({}, inputProps), children),
        !!error && (React.createElement("span", { className: "text-xs mt-1 text-medium text-red-600" }, i18n._(`error__${error.type}`)))));
}
