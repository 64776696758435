var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "@lingui/core";
import cx from "clsx";
import { ActionMessage, Button, Icons, Modal, Spinner } from "../../../../components";
import { EditReportForm, CreateReportForm } from "../../../../containers";
import { deleteReport, getReports } from "../../../../features/reports";
import { Report as ReportItem } from "../../Reports";
import * as selectors from "../../../../selectors";
export function ContractorReports({ companyId, contractorId, className }) {
    const [reportEditable, setReportEditable] = useState();
    const [reportFormOpen, setReportFormOpen] = useState(false);
    const [reportsLoading, setReportsLoading] = useState(true);
    // prettier-ignore
    const reportIds = useSelector((state) => selectors.getReportIdsByContractor(state, contractorId));
    const company = useSelector((state) => selectors.getCompany(state, companyId));
    const reports = useSelector(selectors.getReports);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const getReportList = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active) {
            setReportsLoading(true);
            try {
                yield dispatch(getReports({ companyId, contractorId })).unwrap();
            }
            finally {
                setReportsLoading(false);
            }
        }
    }), [companyId, contractorId, session]);
    const onOpenReportModal = useCallback(() => {
        setReportFormOpen(true);
    }, []);
    useEffect(() => {
        getReportList();
    }, [companyId, contractorId, session]);
    const onEditReport = useCallback((report) => {
        setReportEditable(report);
        setReportFormOpen(true);
    }, [reports]);
    const onDeleteReport = useCallback((report) => {
        dispatch(deleteReport({ id: report.id, companyId: company.id }));
    }, [reports]);
    // prettier-ignore
    const items = reportIds && reportIds.map((id, index) => (React.createElement(ReportItem, { key: `report-${id}-${index}`, onDelete: onDeleteReport, onEdit: onEditReport, id: id, actions: true, short: true })));
    const containerClasses = cx("mx-4 my-4 flex flex-col rounded bg-white dark:bg-grey-800", className, {
        "items-center justify-center p-10 h-40": !(reportIds === null || reportIds === void 0 ? void 0 : reportIds.length),
        "overflow-hidden h-80": reportIds === null || reportIds === void 0 ? void 0 : reportIds.length,
    });
    const modalReportProps = {
        title: i18n._("sidebar__reports"),
        name: "reports_editor",
        open: reportFormOpen,
        onClose: () => {
            setReportEditable(undefined);
            setReportFormOpen(false);
        },
    };
    const formReportProps = {
        onSaved: () => setReportFormOpen(false),
        report: reportEditable,
        companyId: company === null || company === void 0 ? void 0 : company.id,
        contractorId,
    };
    return (React.createElement(React.Fragment, null,
        reportsLoading ? (React.createElement("div", { className: "flex items-center justify-center p-10 flex-1" },
            React.createElement(Spinner, null))) : !(reportIds === null || reportIds === void 0 ? void 0 : reportIds.length) ? (React.createElement(ActionMessage, { title: i18n._("reports_empty_title"), action: i18n._("companies_empty_action"), onClick: onOpenReportModal, className: "mx-4 my-4", icon: Icons.Plus })) : (React.createElement("div", { className: containerClasses },
            React.createElement("div", { className: "flex flex-col flex-1 max-h-72 overflow-y-auto" }, items),
            React.createElement(Button, { className: "w-full rounded-none justify-center border-t border-grey-50 dark:border-grey-700", onClick: onOpenReportModal, color: "transparent" },
                React.createElement(Icons.Plus, { className: "mr-1" }),
                i18n._("add")))),
        reportFormOpen && (React.createElement(Modal, Object.assign({}, modalReportProps),
            React.createElement("div", { className: "flex flex-col px-5 py-2" }, reportFormOpen && reportEditable ? (React.createElement(EditReportForm, Object.assign({}, formReportProps))) : (React.createElement(CreateReportForm, Object.assign({}, formReportProps))))))));
}
