import dayjs from "dayjs";
export function parseDateString(value, originalValue, context) {
    if (context.isType(value)) {
        return value;
    }
    if (context.spec.presence === "optional") {
        return undefined;
    }
    const _date = dayjs(originalValue, "DD.MM.YYYY");
    const parsedDate = _date.isValid() ? _date.toDate() : new Date("");
    return parsedDate;
}
