import React from "react";
import { i18n } from "@lingui/core";
import cx from "clsx";
import { Button } from "../Button";
import * as Icons from "../Icons";
export function Modal(props) {
    const modalClasses = cx("overflow-y-auto overflow-x-hidden fixed", "right-0 left-0 top-4 z-50", "flex justify-center items-center", "h-modal md:h-full md:inset-0", "bg-semi-white dark:bg-semi-black", { hidden: !props.open });
    return (React.createElement("div", { className: modalClasses, "aria-hidden": "true", id: props.name, onClick: props.onClose },
        React.createElement("div", { className: "relative px-4 w-full max-w-2xl h-full md:h-auto" },
            React.createElement("div", { onClick: (e) => e.stopPropagation(), className: "relative rounded border border-grey-200 bg-white dark:border-grey-800 dark:bg-grey-900" },
                React.createElement("div", { className: "flex justify-between items-center px-5 pt-5 pb-2 rounded-t" },
                    React.createElement("h3", { className: "font-semibold text-xl text-grey-900 dark:text-grey-50" }, i18n._(props.title)),
                    React.createElement(Button, { className: "h-8 w-8 p-1", color: "transparent", onClick: props.onClose },
                        React.createElement(Icons.Close, { className: "w-5 h-5" }))),
                props.children))));
}
