import { i18n } from "@lingui/core";
import React from "react";
import cx from "clsx";
function Item({ name, field, value, localize, className }) {
    return (React.createElement("li", { className: cx("py-3 sm:py-4", className) },
        React.createElement("div", { className: "flex items-center space-x-4" },
            React.createElement("div", { className: "flex-1 min-w-0" },
                React.createElement("p", { className: "text-md font-semibold truncate text-grey-900 dark:text-grey-50" }, i18n._(`${name}__${field}`)),
                React.createElement("p", { className: "text-sm mt-1 whitespace-pre-wrap truncate text-grey-500 dark:text-grey-500" }, localize ? i18n._(`${typeof (localize) === "string" ? localize : name}__${value}`) : value ? value.toString() : undefined)),
            React.createElement("div", { className: "inline-flex items-center text-base font-semibold text-grey-900" }))));
}
export function DetailsMap({ name, localize, keys, source, className, itemClassName, inline }) {
    // prettier-ignore
    const items = keys.map((key) => (React.createElement(Item, { className: itemClassName, localize: localize[key], value: source[key], name: name, field: key, key: key })));
    return (React.createElement("ul", { role: "list", className: cx({
            "divide-y divide-grey-200 dark:divide-grey-800": !inline,
            "flex-row": inline,
        }, className) }, items));
}
