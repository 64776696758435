import { Link } from "react-router-dom";
import { i18n } from "@lingui/core";
import React from "react";
import cx from "clsx";
export function RowItem({ company }) {
    const classes = cx("flex flex-col items-start justify-center", "px-8 py-3", "transition duration-300", "text-md font-medium text-grey-900 dark:text-grey-50", "hover:text-grey-600 hover:bg-grey-100 dark:hover:text-grey-100 dark:hover:bg-grey-800");
    return (React.createElement(Link, { to: `/company/${company.id}`, className: classes },
        React.createElement("h4", { className: "font-semibold text-md text-grey-800 dark:text-grey-50" }, company.name),
        React.createElement("p", { className: "font-semibold text-sm text-grey-600 dark:text-grey-400" },
            i18n._("company__representative"),
            ": ",
            company.representative || i18n._("not_specified"))));
}
