var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { i18n } from "@lingui/core";
import * as Yup from "yup";
import cx from "clsx";
import { createReportGroup } from "../../../features/reportGroups";
import { Alert, Button, Spinner } from "../../../components";
import { parseDateString } from "../../../utils";
import { FieldInput } from "../Builder";
const schema = Yup.object({
    start: Yup.date().transform(parseDateString).required(),
    end: Yup.date().transform(parseDateString).required(),
}).required();
// Only create
export function ReportGroupForm({ companyId, onSaved }) {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = useCallback((_form) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            const form = {
                range: {
                    start: _form.start,
                    end: _form.end,
                },
            };
            // prettier-ignore
            const action = createReportGroup({ companyId, reportGroup: form });
            yield dispatch(action).unwrap();
            onSaved === null || onSaved === void 0 ? void 0 : onSaved(form);
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2500);
        }
        finally {
            setLoading(false);
        }
    }), []);
    // prettier-ignore
    return (React.createElement("form", { className: "form relative flex flex-col items-center", onSubmit: handleSubmit(onSubmit) },
        loading && (React.createElement("div", { className: "z-50 flex items-center justify-center absolute top-0 right-0 left-0 bottom-0", style: { background: "rgba(255,255,255, 0.3)" } },
            React.createElement(Spinner, null))),
        React.createElement("div", { className: cx("fields flex flex-col w-full", { "blur-sm": loading }) },
            React.createElement(FieldInput, { placeholder: "report_group__range__start", error: errors.start, validator: register, name: "start", className: "mt-5" }),
            React.createElement(FieldInput, { placeholder: "report_group__range__end", error: errors.end, validator: register, name: "end", className: "mt-5" })),
        error && (React.createElement(Alert, { fill: true, type: "error", className: "mt-5" }, i18n._(error))),
        React.createElement(Button, { color: error ? "red" : "blue", type: "submit", className: cx("mt-4", { "blur-sm": loading }) }, i18n._("report_groups_create_action"))));
}
