export * from "./ChevronLeft";
export * from "./Collection";
export * from "./Briefcase";
export * from "./Document";
export * from "./Academic";
export * from "./Settings";
export * from "./Calendar";
export * from "./Download";
export * from "./Delete";
export * from "./Users";
export * from "./Close";
export * from "./Edit";
export * from "./Home";
export * from "./Plus";
export * from "./Save";
export * from "./Mail";
