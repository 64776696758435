var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { i18n } from "@lingui/core";
import { Icons, Modal, Spinner, PageMenu, ActionMessage } from "../../../components";
import { deleteContractor, getContractors } from "../../../features/contractors";
import { ContractorForm } from "../../../containers";
import * as selectors from "../../../selectors";
import { RowItem } from "./RowItem";
export function CompanyContractors() {
    const [editable, setEditable] = useState();
    const [formOpen, setFormOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const { id: companyId } = useParams();
    const contractorIds = useSelector((state) => selectors.getContractorIdsByCompany(state, companyId));
    const company = useSelector((state) => selectors.getCompany(state, companyId));
    const contractors = useSelector(selectors.getContractors);
    const getContractorList = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active) {
            setLoading(true);
            try {
                yield dispatch(getContractors(companyId)).unwrap();
            }
            finally {
                setLoading(false);
            }
        }
    }), [companyId, session]);
    const onEdit = useCallback((contractor) => {
        setEditable(contractor);
        setFormOpen(true);
    }, [contractors]);
    const onDelete = useCallback((contractor) => {
        dispatch(deleteContractor({
            companyId: contractor.company,
            id: contractor.id,
        }));
    }, [contractors]);
    const onOpenModal = useCallback(() => setFormOpen(true), []);
    // prettier-ignore
    useEffect(() => { getContractorList(); }, [companyId, session]);
    // prettier-ignore
    const items = contractorIds && contractorIds.map((id, index) => (React.createElement(RowItem, Object.assign({ key: `${id}-${index}` }, contractors[id], { onDelete: onDelete, onEdit: onEdit }))));
    const modalProps = {
        title: i18n._("sidebar__contractors"),
        name: "contractors_editor",
        open: formOpen,
        onClose: () => {
            setEditable(undefined);
            setFormOpen(false);
        },
    };
    const formProps = {
        onSaved: () => setFormOpen(false),
        companyId: company === null || company === void 0 ? void 0 : company.id,
        contractor: editable,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageMenu, { title: i18n._("sidebar__contractors"), actions: [
                {
                    icon: Icons.Plus,
                    title: i18n._("add"),
                    onClick: onOpenModal,
                }
            ] }),
        loading || !company ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
            React.createElement(Spinner, null))) : (React.createElement("div", { className: "flex flex-col" }, !(contractorIds === null || contractorIds === void 0 ? void 0 : contractorIds.length) ? (React.createElement(ActionMessage, { title: i18n._("contractors_empty_title"), action: i18n._("companies_empty_action"), className: "mx-20 mt-10", onClick: onOpenModal, icon: Icons.Plus })) : (items))),
        formOpen && (React.createElement(Modal, Object.assign({}, modalProps),
            React.createElement("div", { className: "flex flex-col px-5 py-2" }, formOpen && React.createElement(ContractorForm, Object.assign({}, formProps)))))));
}
