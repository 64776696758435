var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { i18n } from "@lingui/core";
import * as Yup from "yup";
import cx from "clsx";
import { ReportRangeType } from "../../../models";
import { Alert, Button, Spinner } from "../../../components";
import { AdditionalServices } from "./AdditionalServices";
import { updateReport } from "../../../features/reports";
import { FieldInput, FieldSelect } from "../Builder";
const schema = Yup.object({
    type: Yup.mixed().oneOf(Object.values(ReportRangeType)).required(),
    working_days: Yup.number().integer().positive().required(),
    value: Yup.number().positive().required(),
}).required();
// Only create
export function EditReportForm({ companyId, report, onSaved }) {
    const [services, setServices] = useState(report.additional_service);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            type: ReportRangeType.Hours,
            value: report.summary.hours,
        }
    });
    const onSubmit = useCallback((_form) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            const form = {
                worked_time: {
                    working_days: _form.working_days,
                    value: _form.value,
                    type: _form.type,
                },
                additional_service: services,
            };
            // prettier-ignore
            const action = updateReport({ companyId, id: report.id, options: form });
            yield dispatch(action).unwrap();
            onSaved === null || onSaved === void 0 ? void 0 : onSaved(form);
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2500);
        }
        finally {
            setLoading(false);
        }
    }), [services]);
    const onChangeServices = useCallback((services) => {
        setServices(services);
    }, []);
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement("div", { className: "z-50 flex items-center justify-center absolute top-0 right-0 left-0 bottom-0", style: { background: "rgba(255,255,255, 0.3)" } },
            React.createElement(Spinner, null))),
        React.createElement(AdditionalServices, { onChange: onChangeServices, services: services }),
        React.createElement("form", { className: "form relative flex flex-col items-center", onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: cx("fields flex flex-col w-full", { "blur-sm": loading }) },
                React.createElement(FieldSelect, { placeholder: "report_range_type", error: errors.type, validator: register, name: "type", className: "mt-4" },
                    React.createElement("option", { value: ReportRangeType.Days }, i18n._(`report_range_${ReportRangeType.Days}`)),
                    React.createElement("option", { value: ReportRangeType.Hours }, i18n._(`report_range_${ReportRangeType.Hours}`))),
                React.createElement(FieldInput, { placeholder: "report_working_days", error: errors.working_days, validator: register, name: "working_days", className: "mt-5" }),
                React.createElement(FieldInput, { placeholder: "report_value", error: errors.value, validator: register, name: "value", className: "mt-5" })),
            error && (React.createElement(Alert, { fill: true, type: "error", className: "mt-5" }, i18n._(error))),
            React.createElement(Button, { color: error ? "red" : "blue", type: "submit", className: cx("mt-4", { "blur-sm": loading }) }, i18n._("report_update_action")))));
}
