var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { i18n } from "@lingui/core";
import { uploadCompanySignature } from "../../../features/companies";
import * as selectors from "../../../selectors";
import { Image } from "../../../components";
// @TODO: remove inline styles
const baseStyle = {
    transition: "border .24s ease-in-out",
    backgroundColor: "#fafafa",
    flexDirection: "column",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    alignItems: "center",
    color: "#bdbdbd",
    outline: "none",
    display: "flex",
    borderRadius: 8,
    borderWidth: 2,
    flex: 1,
};
const focusedStyle = {
    borderColor: "#2196f3",
};
const acceptStyle = {
    borderColor: "#00e676",
};
const rejectStyle = {
    borderColor: "#ff1744",
};
export function UploadSignature() {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const { id } = useParams();
    const company = useSelector((state) => selectors.getCompany(state, id));
    const dispatch = useDispatch();
    const onDrop = useCallback(([file]) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            const action = uploadCompanySignature({ id: company.id, file, onProgress: progress => setProgress(progress) });
            yield dispatch(action).unwrap();
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
            setProgress(0);
        }
    }), []);
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        maxSize: 2000000,
        accept: "image/*",
        maxFiles: 1,
        onDrop,
    });
    const style = useMemo(() => (Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyle), (isFocused ? focusedStyle : {})), (isDragAccept ? acceptStyle : {})), (isDragReject ? rejectStyle : {}))), [isFocused, isDragAccept, isDragReject]);
    return (React.createElement("div", { className: "flex flex-col mb-5 p-4 flex-1" },
        React.createElement("h4", { className: "font-semibold text-lg mb-4 text-grey-900 dark:text-grey-50" }, i18n._("company_upload_signature")),
        React.createElement("div", Object.assign({}, getRootProps({ style }), { className: "relative p-4" }),
            React.createElement("input", Object.assign({}, getInputProps())),
            React.createElement("p", { className: "font-medium text-lg text-stone-800" }, i18n._("company_upload_signature_placeholder")),
            company.signature && (React.createElement(Image, { src: `${process.env.API_HOST}/api/v1/company/${company.id}/signature/${company.signature}`, className: "mt-3 h-24 max-h-24 max-w-24 rounded-lg" })),
            loading && (React.createElement("div", { className: "flex items-center justify-center px-12 flex-1 absolute top-0 left-0 right-0 bottom-0 bg-slate-50" },
                React.createElement("div", { className: "w-full bg-gray-200 rounded-full" },
                    React.createElement("div", { className: "bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full", style: { width: `${progress}%` } },
                        progress,
                        "%")))))));
}
