import { request } from "./common";
export function getReportGroups(companyId, token) {
    return request(`/company/${companyId}/report-group`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function getReportGroup(companyId, id, token) {
    return request(`/company/${companyId}/report-group/${id}`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function deleteReportGroup(companyId, id, token) {
    return request(`/company/${companyId}/report-group/${id}`, "DELETE", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function createReportGroup(companyId, body, token) {
    return request(`/company/${companyId}/report-group`, "POST", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function previewReportGroup(companyId, body, token) {
    return request(`/company/${companyId}/report-group-preview`, "POST", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
