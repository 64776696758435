var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n } from "@lingui/core";
import * as selectors from "../../selectors";
import * as toast from "../../containers/Toast/actions";
import * as api from "../../api";
export const setInitialized = createAction("professions/initialized");
export const setProfession = createAction("professions/set/profession");
export const setProfessions = createAction("professions/set");
export const unsetProfession = createAction("professions/unset/profession");
export const getProfessions = createAsyncThunk("professions/list", (companyId, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getProfessions(companyId, token);
            dispatch(setProfessions({
                companyId: company.id,
                professions: data,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
    dispatch(setInitialized(true));
}));
export const getProfession = createAsyncThunk("professions/getOne", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getProfession(companyId, id, token);
            dispatch(setProfession({
                companyId: company.id,
                profession: data,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
}));
export const createProfession = createAsyncThunk("professions/createOne", ({ profession, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.createProfession(companyId, profession, token);
            dispatch(setProfession({
                companyId: company.id,
                profession: data,
            }));
            toast.success(i18n._("toast_create_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_create_failed"));
        }
    }
}));
export const deleteProfession = createAsyncThunk("professions/deleteProfession", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            yield api.deleteProfession(companyId, id, token);
            dispatch(unsetProfession(id));
            toast.success(i18n._("toast_delete_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_delete_failed"));
        }
    }
}));
export const updateProfession = createAsyncThunk("professions/updateOne", ({ id, companyId, profession }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.updateProfession(companyId, id, profession, token);
            dispatch(setProfession({
                companyId: company.id,
                profession: data,
            }));
            toast.success(i18n._("toast_update_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_update_failed"));
        }
    }
}));
