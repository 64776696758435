import { useDispatch, useSelector } from "react-redux";
import { i18n } from "@lingui/core";
import React from "react";
import { PageMenu, Spinner, Toggle } from "../../../components";
import { toggleDark } from "../../../features/session";
import * as selectors from "../../../selectors";
export function Settings() {
    const isDarkTheme = useSelector((state) => state.session.darkTheme);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    return (React.createElement(React.Fragment, null,
        React.createElement(PageMenu, { title: i18n._("settings_title") }),
        !session.active ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
            React.createElement(Spinner, null))) : (React.createElement("div", { className: "flex flex-row mx-8 mt-10" },
            React.createElement(Toggle, { label: i18n._("settings__enable_dark"), onChange: () => dispatch(toggleDark()), checked: isDarkTheme, name: "is-dark" })))));
}
