var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from "react";
import { i18n } from "@lingui/core";
import { Button, DetailsMap, Icons } from "../../../components";
export function BankCard(_a) {
    var { onEdit, onDelete } = _a, bank = __rest(_a, ["onEdit", "onDelete"]);
    const onClickEdit = useCallback(() => {
        onEdit === null || onEdit === void 0 ? void 0 : onEdit(bank);
    }, [onEdit, bank]);
    const onClickDelete = useCallback(() => {
        onDelete === null || onDelete === void 0 ? void 0 : onDelete(bank);
    }, [onDelete, bank]);
    return (React.createElement("div", { className: "flex flex-row relative w-1/4 h-80 mr-4 overflow-hidden" },
        React.createElement("div", { className: "flex absolute right-4 top-6" },
            bank.is_default && (React.createElement("div", { className: "flex items-center px-2 mr-2 rounded text-xs text-white bg-grey-900 dark:bg-blue-grey-900" }, i18n._("is_default"))),
            React.createElement(Button, { className: "h-8 w-8 p-0 mr-2 flex items-center justify-center", onClick: onClickDelete, color: "transparent", type: "button" },
                React.createElement(Icons.Delete, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-50" })),
            React.createElement(Button, { className: "h-8 w-8 p-0 flex items-center justify-center", onClick: onClickEdit, color: "transparent", type: "button" },
                React.createElement(Icons.Edit, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-50" }))),
        React.createElement(DetailsMap, { className: "rounded flex-1 border border-grey-200 bg-white dark:border-gray-800 dark:bg-grey-800", keys: ["name", "detail"], itemClassName: "px-5", source: bank, localize: {}, name: "bank" })));
}
