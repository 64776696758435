import { HashRouter } from "react-router-dom";
import { I18nProvider } from "@lingui/react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import React from "react";
import parseDatePlugin from "dayjs/plugin/customParseFormat";
import * as dayjs from "dayjs";
import { initLocales } from "./locales";
import { store } from "./store";
import { App } from "./App";
// import "inter-ui/inter.css"
import "source-sans/source-sans-3.css";
import "./styles/index.pcss";
dayjs.extend(parseDatePlugin);
const root = document.querySelector("main");
const i18n = initLocales();
render(React.createElement(I18nProvider, { i18n: i18n },
    React.createElement(Provider, { store: store },
        React.createElement(HashRouter, null,
            React.createElement(App, null)))), root);
