var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "@lingui/core";
import cx from "clsx";
import { ActionMessage, Button, Icons, Modal, Spinner } from "../../../../components";
import { getContracts } from "../../../../features/contracts";
import { ContractForm } from "../../../../containers";
import * as selectors from "../../../../selectors";
import { ContractRow } from "./Row";
export function ContractorContracts({ companyId, contractorId }) {
    const [contractEditable, setContractEditable] = useState();
    const [contractFormOpen, setContractFormOpen] = useState(false);
    const [contractsLoading, setContractsLoading] = useState(true);
    // prettier-ignore
    const contractIds = useSelector((state) => selectors.getContractIdsByContractor(state, contractorId));
    const company = useSelector((state) => selectors.getCompany(state, companyId));
    const contracts = useSelector(selectors.getContracts);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const getContractList = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active) {
            setContractsLoading(true);
            try {
                yield dispatch(getContracts({ companyId, contractorId })).unwrap();
            }
            finally {
                setContractsLoading(false);
            }
        }
    }), [companyId, contractorId, session]);
    useEffect(() => {
        getContractList();
    }, [companyId, contractorId, session]);
    const onOpenContractModal = useCallback(() => setContractFormOpen(true), []);
    const onEditContract = useCallback((contract) => {
        setContractEditable(contract);
        setContractFormOpen(true);
    }, [contracts]);
    const onDeleteContract = useCallback((contract) => {
        // @TODO
    }, [contracts]);
    // prettier-ignore
    const items = contractIds && contractIds.map((id, index) => (React.createElement(ContractRow, Object.assign({ onDelete: onDeleteContract, onEdit: onEditContract, key: id + index }, contracts[id]))));
    const containerClasses = cx("flex flex-col rounded my-4 mx-4 bg-white dark:bg-grey-800", {
        "items-center justify-center p-10 h-40": !(contractIds === null || contractIds === void 0 ? void 0 : contractIds.length),
        "overflow-hidden h-80": contractIds === null || contractIds === void 0 ? void 0 : contractIds.length,
    });
    const list = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col flex-1 max-h-72 overflow-y-auto" }, items),
        React.createElement(Button, { className: "w-full rounded-none justify-center border-t border-grey-50 dark:border-grey-700", onClick: onOpenContractModal, color: "transparent" },
            React.createElement(Icons.Plus, { className: "mr-1" }),
            i18n._("add"))));
    const modalContractProps = {
        title: i18n._("sidebar__contracts"),
        name: "contracts_editor",
        open: contractFormOpen,
        onClose: () => {
            setContractEditable(undefined);
            setContractFormOpen(false);
        },
    };
    const formContractProps = {
        onSaved: () => setContractFormOpen(false),
        contract: contractEditable,
        companyId: company === null || company === void 0 ? void 0 : company.id,
        contractorId,
    };
    return (React.createElement(React.Fragment, null,
        contractsLoading ? (React.createElement("div", { className: "flex items-center justify-center p-10 flex-1" },
            React.createElement(Spinner, null))) : !(contractIds === null || contractIds === void 0 ? void 0 : contractIds.length) ? (React.createElement(ActionMessage, { title: i18n._("contracts_empty_title"), action: i18n._("companies_empty_action"), onClick: onOpenContractModal, className: "mx-4 my-4", icon: Icons.Plus })) : (React.createElement("div", { className: containerClasses }, list)),
        contractFormOpen && (React.createElement(Modal, Object.assign({}, modalContractProps),
            React.createElement("div", { className: "flex flex-col px-5 py-2" }, contractFormOpen && React.createElement(ContractForm, Object.assign({}, formContractProps)))))));
}
