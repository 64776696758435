import { createSlice } from "@reduxjs/toolkit";
import { getProfessions, getProfession, setProfessions, setProfession, setInitialized, unsetProfession, } from "./actions";
export * from "./actions";
const initialState = {
    initialized: false,
    byCompaniesIds: {},
    byCompanies: {},
    collection: {},
    ids: [],
};
const extraReducers = {
    [setProfessions.type]: (state, { payload: { companyId, professions } }) => {
        for (const profession of professions) {
            state.byCompanies[profession.id] = companyId;
            state.collection[profession.id] = profession;
            state.ids.push(profession.id);
            if (!state.byCompaniesIds[companyId]) {
                state.byCompaniesIds[companyId] = [];
            }
            // prettier-ignore
            state.byCompaniesIds[companyId] = Array.from(new Set([
                ...state.byCompaniesIds[companyId],
                profession.id
            ])).sort();
        }
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [setProfession.type]: (state, { payload: { companyId, profession } }) => {
        state.byCompanies[profession.id] = companyId;
        state.collection[profession.id] = profession;
        state.ids.push(profession.id);
        if (!state.byCompaniesIds[companyId]) {
            state.byCompaniesIds[companyId] = [];
        }
        // prettier-ignore
        state.byCompaniesIds[companyId] = Array.from(new Set([
            ...state.byCompaniesIds[companyId],
            profession.id
        ])).sort();
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [unsetProfession.type]: (state, { payload: id }) => {
        const companyId = state.byCompanies[id];
        state.byCompaniesIds[companyId] = state.byCompaniesIds[companyId].filter((_id) => id !== _id);
        delete state.byCompanies[id];
        delete state.collection[id];
        state.ids.splice(state.ids.indexOf(id), 1);
    },
    [setInitialized.type]: (state, { payload }) => {
        state.initialized = payload;
    },
    [getProfessions.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
    [getProfession.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
};
export const professionsSlice = createSlice({
    name: "professions",
    initialState,
    extraReducers,
    reducers: {},
});
// Action creators are generated for each case reducer function
// export const {
//   // @TODO
// } = professionsSlice.actions
export default professionsSlice.reducer;
