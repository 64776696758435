var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors";
import { Button } from "../Button";
function downloadBlob(link, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    downloadLink.href = link;
    downloadLink.click();
}
export function DownloadButton(_a) {
    var { src, filename, children } = _a, props = __rest(_a, ["src", "filename", "children"]);
    const session = useSelector(selectors.getSession);
    const download = useCallback(() => {
        fetch(src, {
            headers: { Authorization: `Bearer ${session.token}` },
            method: "GET",
        })
            .then(response => {
            if (response.status != 200) {
                throw new Error("Bad server response");
            }
            return response.blob();
        })
            .then(blob => {
            const link = window.URL.createObjectURL(blob);
            downloadBlob(link, filename);
        });
    }, [src, session]);
    return (React.createElement(Button, Object.assign({}, props, { onClick: download }), children));
}
