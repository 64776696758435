var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { i18n } from "@lingui/core";
import * as dayjs from "dayjs";
import * as Yup from "yup";
import cx from "clsx";
import { ContractType, SalaryCurrency, SalaryType } from "../../../models";
import { createContract, updateContract } from "../../../features/contracts";
import { Alert, Button, Spinner, Toggle } from "../../../components";
import { FieldInput, FieldSelect } from "../Builder";
import { parseDateString } from "../../../utils";
const schema = Yup.object({
    contract_type: Yup.mixed().oneOf(Object.values(ContractType)).required(),
    started_date: Yup.date().transform(parseDateString).required(),
    ended_date: Yup.date().optional().transform(parseDateString).optional(),
    is_active: Yup.mixed().oneOf([true, false]).required(),
    signed: Yup.mixed().oneOf([true, false]).required(),
    number: Yup.string().required(),
    salary__currency: Yup.mixed().oneOf(Object.values(SalaryCurrency)).required(),
    salary__type: Yup.mixed().oneOf(Object.values(SalaryType)).required(),
    salary__amount: Yup.number().positive().required(),
}).required();
export function ContractForm({ contract, companyId, contractorId, onSaved }) {
    var _a, _b, _c;
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: Object.assign({}, contract && {
            number: contract.number,
            ended_date: contract.ended_date ? dayjs(contract.ended_date).format("DD.MM.YYYY") : undefined,
            started_date: dayjs(contract.started_date).format("DD.MM.YYYY"),
            contract_type: contract.contract_type,
            is_active: contract.is_active,
            signed: contract.signed,
            salary__currency: (_a = contract === null || contract === void 0 ? void 0 : contract.salary) === null || _a === void 0 ? void 0 : _a.currency,
            salary__amount: ((_b = contract === null || contract === void 0 ? void 0 : contract.salary) === null || _b === void 0 ? void 0 : _b.amount) / 100,
            salary__type: (_c = contract === null || contract === void 0 ? void 0 : contract.salary) === null || _c === void 0 ? void 0 : _c.type,
        })
    });
    const onSubmit = useCallback((_form) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            const form = {};
            {
                const { salary__currency, salary__amount, salary__type } = _form, rest = __rest(_form, ["salary__currency", "salary__amount", "salary__type"]);
                Object.assign(form, rest, {
                    salary: {
                        amount: salary__amount * 100,
                        currency: salary__currency,
                        type: salary__type,
                    },
                });
            }
            // prettier-ignore
            const action = contract
                ? updateContract({ companyId, contractorId, id: contract.id, contract: form })
                : createContract({ companyId, contractorId, contract: form });
            yield dispatch(action).unwrap();
            onSaved === null || onSaved === void 0 ? void 0 : onSaved(form);
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2500);
        }
        finally {
            setLoading(false);
        }
    }), []);
    // prettier-ignore
    return (React.createElement("form", { className: "form relative flex flex-col items-center", onSubmit: handleSubmit(onSubmit) },
        loading && (React.createElement("div", { className: "z-50 flex items-center justify-center absolute top-0 right-0 left-0 bottom-0", style: { background: "rgba(255,255,255, 0.3)" } },
            React.createElement(Spinner, null))),
        React.createElement("div", { className: cx("fields flex flex-col w-full", { "blur-sm": loading }) },
            React.createElement(FieldInput, { placeholder: "contract__number", validator: register, error: errors.number, className: "mt-4", name: "number" }),
            React.createElement(FieldSelect, { placeholder: "company__contract_type", error: errors.contract_type, validator: register, name: "contract_type", className: "mt-4" },
                React.createElement("option", { value: ContractType.SelfEmployed }, i18n._(`contract__${ContractType.SelfEmployed}`)),
                React.createElement("option", { value: ContractType.SoleProprietor }, i18n._(`contract__${ContractType.SoleProprietor}`))),
            React.createElement("div", { className: "flex flex-row mt-4" },
                React.createElement(FieldSelect, { placeholder: "company__salary__currency", error: errors.salary__currency, validator: register, name: "salary__currency", className: "mr-2" },
                    React.createElement("option", { value: SalaryCurrency.Eur }, SalaryCurrency.Eur),
                    React.createElement("option", { value: SalaryCurrency.Usd }, SalaryCurrency.Usd),
                    React.createElement("option", { value: SalaryCurrency.Rub }, SalaryCurrency.Rub)),
                React.createElement(FieldSelect, { placeholder: "company__salary__type", error: errors.salary__type, validator: register, name: "salary__type", className: "mr-2" },
                    React.createElement("option", { value: SalaryType.Hour }, i18n._(`contract__salary__${SalaryType.Hour}`)),
                    React.createElement("option", { value: SalaryType.Month }, i18n._(`contract__salary__${SalaryType.Month}`))),
                React.createElement(FieldInput, { placeholder: "contract__salary__amount", validator: register, error: errors.salary__amount, name: "salary__amount", className: "flex-1" })),
            React.createElement("div", { className: "flex flex-row mt-4" },
                React.createElement(FieldInput, { placeholder: "contract__started_date", validator: register, error: errors.started_date, name: "started_date", className: "flex-1 mr-2" }),
                React.createElement(FieldInput, { placeholder: "contract__ended_date", validator: register, error: errors.ended_date, name: "ended_date", className: "flex-1" })),
            React.createElement("div", { className: "flex flex-col mt-4" },
                React.createElement(Toggle, { label: i18n._("contract__signed"), error: !!errors.signed, validator: register, name: "signed" })),
            React.createElement("div", { className: "flex flex-col mt-4" },
                React.createElement(Toggle, { label: i18n._("contract__is_active"), error: !!errors.is_active, validator: register, name: "is_active" }))),
        error && (React.createElement(Alert, { fill: true, type: "error", className: "mt-5" }, i18n._(error))),
        React.createElement(Button, { color: error ? "red" : "blue", type: "submit", className: cx("mt-4", { "blur-sm": loading }) }, i18n._(contract ? "contracts_update_action" : "contracts_create_action"))));
}
