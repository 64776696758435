import { createSelector } from "@reduxjs/toolkit";
export const getToken = (state) => state.session.token;
export const getSession = (state) => state.session;
// Companies
export const getCompaniesInitialized = (state) => state.companies.initialized;
export const getCompanies = (state) => state.companies.collection;
export const getCompaniesIds = (state) => state.companies.ids;
export const getCompany = createSelector([getCompanies, (state, id) => id], (companies, id) => companies[id]);
// Banks
export const getBanksInitialized = (state) => state.banks.initialized;
export const getBanks = (state) => state.banks.collection;
export const getBanksIds = (state) => state.banks.ids;
export const getBank = createSelector([getBanks, (state, id) => id], (banks, id) => banks[id]);
// prettier-ignore
export const getBankIdsByCompany = createSelector([
    (state) => state.banks.byCompaniesIds,
    (state, companyId) => companyId,
], (companies, id) => companies[id]);
// Professions
export const getProfessionsInitialized = (state) => state.professions.initialized;
export const getProfessions = (state) => state.professions.collection;
export const getProfessionsIds = (state) => state.professions.ids;
export const getProfession = createSelector([getProfessions, (state, id) => id], (professions, id) => professions[id]);
// prettier-ignore
export const getProfessionIdsByCompany = createSelector([
    (state) => state.professions.byCompaniesIds,
    (state, companyId) => companyId,
], (professions, id) => professions[id]);
// Contractors
export const getContractorsInitialized = (state) => state.contractors.initialized;
export const getContractors = (state) => state.contractors.collection;
export const getContractorsIds = (state) => state.contractors.ids;
export const getContractor = createSelector([getContractors, (state, id) => id], (contractors, id) => contractors[id]);
// prettier-ignore
export const getContractorIdsByCompany = createSelector([
    (state) => state.contractors.byCompaniesIds,
    (state, companyId) => companyId,
], (contractors, id) => contractors[id]);
// Contracts
export const getContractsInitialized = (state) => state.contracts.initialized;
export const getContracts = (state) => state.contracts.collection;
export const getContractsIds = (state) => state.contracts.ids;
export const getContract = createSelector([getContracts, (state, id) => id], (contracts, id) => contracts[id]);
// prettier-ignore
export const getContractIdsByContractor = createSelector([
    (state) => state.contracts.byContractorsIds,
    (state, contractorId) => contractorId,
], (contracts, contractorId) => contracts[contractorId]);
// Report Groups
export const getReportGroupsInitialized = (state) => state.reportGroups.initialized;
export const getReportGroups = (state) => state.reportGroups.collection;
export const getReportGroupsIds = (state) => state.reportGroups.ids;
export const getReportGroup = createSelector([getReportGroups, (state, id) => id], (reportGroups, id) => reportGroups[id]);
// prettier-ignore
export const getReportGroupIdsByCompany = createSelector([
    (state) => state.reportGroups.byCompaniesIds,
    (state, companyId) => companyId,
], (companies, id) => companies[id]);
// Reports
export const getReportsInitialized = (state) => state.reports.initialized;
export const getReports = (state) => state.reports.collection;
export const getReportsIds = (state) => state.reports.ids;
export const getReport = createSelector([getReports, (state, id) => id], (reports, id) => reports[id]);
// prettier-ignore
export const getReportIdsByContractor = createSelector([
    (state) => state.reports.byContractorsIds,
    (state, contractorId) => contractorId,
], (contracts, contractorId) => contracts[contractorId]);
