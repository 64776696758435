var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n } from "@lingui/core";
import { unsetContractsByContractor } from "../contracts";
import { unsetReportsByContractor } from "../reports";
import * as selectors from "../../selectors";
import * as toast from "../../containers/Toast/actions";
import * as api from "../../api";
export const setContractor = createAction("contractors/set/contractor");
export const setContractors = createAction("contractors/set");
export const unsetContractor = createAction("contractors/unset/contractor");
export const setInitialized = createAction("contractors/initialized");
const format = (_a) => {
    var { profession } = _a, rest = __rest(_a, ["profession"]);
    return (Object.assign(Object.assign({}, rest), { profession: profession === null || profession === void 0 ? void 0 : profession.id }));
};
export const getContractors = createAsyncThunk("contractors/list", (companyId, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getContractors(companyId, token);
            dispatch(setContractors({
                companyId: company.id,
                contractors: data.map(format),
            }));
        }
        catch (error) {
            // @TODO
        }
    }
    dispatch(setInitialized(true));
}));
export const getContractor = createAsyncThunk("contractors/getOne", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getContractor(companyId, id, token);
            dispatch(setContractor({
                companyId: company.id,
                contractor: format(data),
            }));
        }
        catch (error) {
            // @TODO
        }
    }
}));
export const createContractor = createAsyncThunk("contractors/createOne", ({ contractor, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.createContractor(companyId, contractor, token);
            dispatch(setContractor({
                companyId: company.id,
                contractor: format(data),
            }));
            toast.success(i18n._("toast_create_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_create_failed"));
        }
    }
}));
export const deleteContractor = createAsyncThunk("contractors/deleteContractor", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            yield api.deleteContractor(companyId, id, token);
            dispatch(unsetContractsByContractor(id));
            dispatch(unsetReportsByContractor(id));
            dispatch(unsetContractor(id));
            toast.success(i18n._("toast_delete_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_delete_failed"));
        }
    }
}));
export const updateContractor = createAsyncThunk("contractors/updateOne", ({ id, companyId, contractor }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.updateContractor(companyId, id, contractor, token);
            dispatch(setContractor({
                companyId: company.id,
                contractor: format(data),
            }));
            toast.success(i18n._("toast_update_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_update_failed"));
        }
    }
}));
