var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n } from "@lingui/core";
import { setReports, unsetReport } from "../reports/actions";
import { setProfessions } from "../professions/actions";
import { setContractors } from "../contractors/actions";
import { format as formatReport } from "../reports";
import * as selectors from "../../selectors";
import * as toast from "../../containers/Toast/actions";
import * as api from "../../api";
export const setInitialized = createAction("report_groups/initialized");
export const setReportGroup = createAction("report_groups/set/report_group");
export const unsetReportGroup = createAction("report_groups/unset/report_group");
export const setReportGroups = createAction("report_groups/set");
const format = (_a) => {
    var { reports } = _a, rest = __rest(_a, ["reports"]);
    return (Object.assign({ reports: reports.map(({ id }) => id) }, rest));
};
export const getReportGroups = createAsyncThunk("report_groups/list", (companyId, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getReportGroups(companyId, token);
            const contractors = [];
            const professions = [];
            const groups = [];
            const reports = [];
            for (const group of data) {
                for (const report of group.reports) {
                    contractors.push(report.contractor);
                    if ((_a = report.contractor) === null || _a === void 0 ? void 0 : _a.profession) {
                        professions.push((_b = report.contractor) === null || _b === void 0 ? void 0 : _b.profession);
                    }
                    reports.push(formatReport(report));
                }
                groups.push(format(Object.assign(Object.assign({}, group), { company: company.id })));
            }
            dispatch(setProfessions({
                companyId: company.id,
                professions,
            }));
            dispatch(setContractors({
                companyId: company.id,
                contractors,
            }));
            dispatch(setReports({
                companyId: company.id,
                reports,
            }));
            dispatch(setReportGroups({
                companyId: company.id,
                reportGroups: groups,
            }));
        }
        catch (error) {
            console.error(error);
        }
    }
    dispatch(setInitialized(true));
}));
export const getReportGroup = createAsyncThunk("report_groups/getOne", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c, _d;
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getReportGroup(companyId, id, token);
            const contractors = [];
            const professions = [];
            const reports = [];
            for (const report of data.reports) {
                contractors.push(report.contractor);
                if ((_c = report.contractor) === null || _c === void 0 ? void 0 : _c.profession) {
                    professions.push((_d = report.contractor) === null || _d === void 0 ? void 0 : _d.profession);
                }
                reports.push(formatReport(report));
            }
            dispatch(setProfessions({
                companyId: company.id,
                professions,
            }));
            dispatch(setContractors({
                companyId: company.id,
                contractors,
            }));
            dispatch(setReports({
                companyId: company.id,
                reports,
            }));
            dispatch(setReportGroup({
                reportGroup: format(Object.assign(Object.assign({}, data), { company: company.id })),
                companyId: company.id,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
}));
export const createReportGroup = createAsyncThunk("report_groups/createOne", ({ reportGroup, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _e, _f;
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.createReportGroup(companyId, reportGroup, token);
            const contractors = [];
            const professions = [];
            const reports = [];
            for (const report of data.reports) {
                contractors.push(report.contractor);
                if ((_e = report.contractor) === null || _e === void 0 ? void 0 : _e.profession) {
                    professions.push((_f = report.contractor) === null || _f === void 0 ? void 0 : _f.profession);
                }
                reports.push(formatReport(report));
            }
            dispatch(setProfessions({
                companyId: company.id,
                professions,
            }));
            dispatch(setContractors({
                companyId: company.id,
                contractors,
            }));
            dispatch(setReports({
                companyId: company.id,
                reports,
            }));
            dispatch(setReportGroup({
                reportGroup: format(Object.assign(Object.assign({}, data), { company: company.id })),
                companyId: company.id,
            }));
            toast.success(i18n._("toast_create_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_create_failed"));
        }
    }
}));
export const deleteReportGroup = createAsyncThunk("report_groups/deleteReportGroup", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    const reportGroup = selectors.getReportGroup(state, id);
    if (token) {
        try {
            yield api.deleteReportGroup(companyId, id, token);
            dispatch(unsetReportGroup(id));
            reportGroup === null || reportGroup === void 0 ? void 0 : reportGroup.reports.forEach(reportId => dispatch(unsetReport(reportId)));
            toast.success(i18n._("toast_delete_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_delete_failed"));
        }
    }
}));
