import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import React, { useEffect } from "react";
import cx from "clsx";
import { initSession } from "./features/session";
import { Toast } from "./containers";
import * as Page from "./pages";
export function App() {
    const initialized = useSelector((state) => state.session.initialized);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initSession());
    }, []);
    return (React.createElement("div", { className: cx("page-container", { blured: !initialized }) },
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(Page.Landing, null) }),
            React.createElement(Route, { path: "/signup", element: React.createElement(Page.Auth.SignUp, null) }),
            React.createElement(Route, { path: "/signin", element: React.createElement(Page.Auth.SignIn, null) }),
            React.createElement(Route, { path: "/recovery", element: React.createElement(Page.Auth.Recovery, null) }),
            React.createElement(Route, { path: "/company/:id", element: React.createElement(Page.Company, null) },
                React.createElement(Route, { index: true, element: React.createElement(Page.CompanyDetails, null) }),
                React.createElement(Route, { path: "report-groups", element: React.createElement(Page.CompanyReportGroups, null) }),
                React.createElement(Route, { path: "professions", element: React.createElement(Page.CompanyProfession, null) }),
                React.createElement(Route, { path: "banks", element: React.createElement(Page.CompanyBanks, null) }),
                React.createElement(Route, { path: "contractors", element: React.createElement(Page.CompanyContractors, null) }),
                React.createElement(Route, { path: "contractors/:contractorId", element: React.createElement(Page.CompanyContractor, null) })),
            React.createElement(Route, { path: "/dashboard", element: React.createElement(Page.Dashboard, null) },
                React.createElement(Route, { index: true, element: React.createElement(Page.CompaniesList, null) }),
                React.createElement(Route, { path: "settings", element: React.createElement(Page.Settings, null) }),
                React.createElement(Route, { path: "profile", element: React.createElement(Page.Profile, null) }))),
        React.createElement(Toast, null)));
}
