import { createSlice } from "@reduxjs/toolkit";
import { getBanks, getBank, setBanks, setBank, setInitialized, unsetBank } from "./actions";
export * from "./actions";
const initialState = {
    initialized: false,
    byCompaniesIds: {},
    byCompanies: {},
    collection: {},
    ids: [],
};
const extraReducers = {
    [setBanks.type]: (state, { payload: { companyId, banks } }) => {
        for (const bank of banks) {
            state.byCompanies[bank.id] = companyId;
            state.collection[bank.id] = bank;
            state.ids.push(bank.id);
            if (!state.byCompaniesIds[companyId]) {
                state.byCompaniesIds[companyId] = [];
            }
            // prettier-ignore
            state.byCompaniesIds[companyId] = Array.from(new Set([
                ...state.byCompaniesIds[companyId],
                bank.id
            ])).sort();
        }
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [setBank.type]: (state, { payload: { companyId, bank } }) => {
        state.byCompanies[bank.id] = companyId;
        state.collection[bank.id] = bank;
        state.ids.push(bank.id);
        if (!state.byCompaniesIds[companyId]) {
            state.byCompaniesIds[companyId] = [];
        }
        // prettier-ignore
        state.byCompaniesIds[companyId] = Array.from(new Set([
            ...state.byCompaniesIds[companyId],
            bank.id
        ])).sort();
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [unsetBank.type]: (state, { payload: id }) => {
        const companyId = state.byCompanies[id];
        state.byCompaniesIds[companyId] = state.byCompaniesIds[companyId].filter((_id) => id !== _id);
        delete state.byCompanies[id];
        delete state.collection[id];
        state.ids.splice(state.ids.indexOf(id), 1);
    },
    [setInitialized.type]: (state, { payload }) => {
        state.initialized = payload;
    },
    [getBanks.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
    [getBank.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
};
export const banksSlice = createSlice({
    name: "banks",
    initialState,
    extraReducers,
    reducers: {},
});
// Action creators are generated for each case reducer function
// export const {
//   // @TODO
// } = banksSlice.actions
export default banksSlice.reducer;
