import { createSlice } from "@reduxjs/toolkit";
export * from "./actions";
import { unsetReportsByContractor, setInitialized, unsetReport, getReports, setReports, getReport, setReport, } from "./actions";
const initialState = {
    initialized: false,
    byContractorsIds: {},
    byCompaniesIds: {},
    byContractors: {},
    byCompanies: {},
    collection: {},
    ids: [],
};
const extraReducers = {
    [setReports.type]: (state, { payload: { companyId, reports } }) => {
        for (const report of reports) {
            state.byContractors[report.id] = report.contractor;
            state.byCompanies[report.id] = companyId;
            state.collection[report.id] = report;
            state.ids.push(report.id);
            if (!state.byCompaniesIds[companyId]) {
                state.byCompaniesIds[companyId] = [];
            }
            if (!state.byContractorsIds[report.contractor]) {
                state.byContractorsIds[report.contractor] = [];
            }
            // prettier-ignore
            state.byCompaniesIds[companyId] = Array.from(new Set([
                ...state.byCompaniesIds[companyId],
                report.id
            ])).sort();
            // prettier-ignore
            state.byContractorsIds[report.contractor] = Array.from(new Set([
                ...state.byContractorsIds[report.contractor],
                report.id
            ])).sort();
        }
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [setReport.type]: (state, { payload: { companyId, report } }) => {
        state.byContractors[report.id] = report.contractor;
        state.byCompanies[report.id] = companyId;
        state.collection[report.id] = report;
        state.ids.push(report.id);
        if (!state.byCompaniesIds[companyId]) {
            state.byCompaniesIds[companyId] = [];
        }
        if (!state.byContractorsIds[report.contractor]) {
            state.byContractorsIds[report.contractor] = [];
        }
        // prettier-ignore
        state.byCompaniesIds[companyId] = Array.from(new Set([
            ...state.byCompaniesIds[companyId],
            report.id
        ])).sort();
        // prettier-ignore
        state.byContractorsIds[report.contractor] = Array.from(new Set([
            ...state.byContractorsIds[report.contractor],
            report.id
        ])).sort();
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [unsetReport.type]: (state, { payload: id }) => {
        const contractorId = state.byContractors[id];
        const companyId = state.byCompanies[id];
        state.byContractorsIds[contractorId] = state.byContractorsIds[contractorId].filter((_id) => id !== _id);
        state.byCompaniesIds[companyId] = state.byCompaniesIds[companyId].filter((_id) => id !== _id);
        delete state.byContractors[id];
        delete state.byCompanies[id];
        delete state.collection[id];
        state.ids.splice(state.ids.indexOf(id), 1);
    },
    [unsetReportsByContractor.type]: (state, { payload: contractorId }) => {
        const ids = state.byContractorsIds[contractorId];
        if (!ids) {
            return;
        }
        for (const id of ids) {
            const companyId = state.byCompanies[id];
            state.byCompaniesIds[companyId] = state.byCompaniesIds[companyId].filter((_id) => id !== _id);
            delete state.byContractors[id];
            delete state.byCompanies[id];
            delete state.collection[id];
            state.ids.splice(state.ids.indexOf(id), 1);
        }
        delete state.byContractorsIds[contractorId];
    },
    [setInitialized.type]: (state, { payload }) => {
        state.initialized = payload;
    },
    [getReports.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
    [getReport.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
};
export const reportsSlice = createSlice({
    name: "reports",
    initialState,
    extraReducers,
    reducers: {},
});
// Action creators are generated for each case reducer function
// export const {
//   // @TODO
// } = reportsSlice.actions
export default reportsSlice.reducer;
