var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n } from "@lingui/core";
import * as selectors from "../../selectors";
import * as toast from "../../containers/Toast/actions";
import * as api from "../../api";
export const setInitialized = createAction("banks/initialized");
export const setBank = createAction("banks/set/bank");
export const setBanks = createAction("banks/set");
export const unsetBank = createAction("banks/unset/bank");
export const getBanks = createAsyncThunk("banks/list", (companyId, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getBanks(companyId, token);
            dispatch(setBanks({
                companyId: company.id,
                banks: data,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
    dispatch(setInitialized(true));
}));
export const getBank = createAsyncThunk("banks/getOne", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.getBank(companyId, id, token);
            dispatch(setBank({
                companyId: company.id,
                bank: data,
            }));
        }
        catch (error) {
            // @TODO
        }
    }
}));
export const createBank = createAsyncThunk("banks/createOne", ({ bank, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.createBank(companyId, bank, token);
            dispatch(setBank({
                companyId: company.id,
                bank: data,
            }));
            toast.success(i18n._("toast_create_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_create_failed"));
        }
    }
}));
export const updateBank = createAsyncThunk("banks/updateOne", ({ id, companyId, bank }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.updateBank(companyId, id, bank, token);
            dispatch(setBank({
                companyId: company.id,
                bank: data,
            }));
            toast.success(i18n._("toast_update_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_update_failed"));
        }
    }
}));
export const deleteBank = createAsyncThunk("banks/deleteBank", ({ id, companyId }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data, meta: { company } } = yield api.deleteBank(companyId, id, token);
            dispatch(unsetBank(id));
            toast.success(i18n._("toast_delete_success"));
        }
        catch (error) {
            toast.error(i18n._("toast_delete_failed"));
        }
    }
}));
