import { request } from "./common";
export function getProfessions(companyId, token) {
    return request(`/company/${companyId}/profession`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function getProfession(companyId, id, token) {
    return request(`/company/${companyId}/profession/${id}`, "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function deleteProfession(companyId, id, token) {
    return request(`/company/${companyId}/profession/${id}`, "DELETE", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function updateProfession(companyId, id, body, token) {
    return request(`/company/${companyId}/profession/${id}`, "PATCH", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function createProfession(companyId, body, token) {
    return request(`/company/${companyId}/profession`, "POST", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
