var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export const API_HOST = process.env.API_HOST;
export const API_PREFIX = "/api/v1";
function getError(url, method, response) {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield response.json();
        const { status } = response;
        return {
            method,
            status,
            data,
            url,
        };
    });
}
export function request(url, method = "GET", _a = {}) {
    var { headers } = _a, options = __rest(_a, ["headers"]);
    return __awaiter(this, void 0, void 0, function* () {
        const URL = `${API_HOST}${API_PREFIX}${url}`;
        const response = yield fetch(URL, Object.assign({ headers: Object.assign({ "Content-Type": "application/json" }, headers), method }, options));
        if (!response.ok) {
            const error = yield getError(URL, method, response);
            throw error;
        }
        else {
            const data = yield response.json();
            return data;
        }
    });
}
