var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { getCompany } from "../../features/companies";
import * as selectors from "../../selectors";
import { Layout } from "../../containers";
export function Company() {
    const [loading, setLoading] = useState(true);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const { id: companyId } = useParams();
    const getOne = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active) {
            setLoading(true);
            try {
                yield dispatch(getCompany(companyId)).unwrap();
            }
            finally {
                setLoading(false);
            }
        }
    }), [companyId, session]);
    // prettier-ignore
    useEffect(() => { getOne(); }, [companyId, session]);
    return (React.createElement(Layout, { type: "company", companyId: parseInt(companyId) || 0 },
        React.createElement(Outlet, null)));
}
