import { Toaster } from "react-hot-toast";
import React from "react";
export function Toast() {
    const options = {
        // Define default options
        className: "",
        duration: 5000,
        style: {
            background: "#363636",
            color: "#fff",
        },
        // Default options for specific types
        success: {
            duration: 3000,
            theme: {
                primary: "green",
                secondary: "black",
            },
        },
    };
    const props = {
        containerClassName: "",
        position: "top-center",
        toastOptions: options,
        reverseOrder: false,
        containerStyle: {},
        gutter: 8,
    };
    return (React.createElement(Toaster, Object.assign({}, props)));
}
