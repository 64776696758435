var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Link } from "react-router-dom";
import React from "react";
import cx from "clsx";
export function Button(_a) {
    var { className, children, fill, color = "blue", to } = _a, props = __rest(_a, ["className", "children", "fill", "color", "to"]);
    const classes = cx("rounded", "flex flex-row items-center", "font-semibold", "text-center", "text-white", "uppercase", "text-sm", "focus:outline-none", "focus:ring-4", "hover:ease-in", "duration-200", "ease-out", {
        "w-full": fill,
        [["py-2.5", "px-5"].join(" ")]: !(className || "").includes("p-"),
        [["bg-blue-500", "hover:bg-blue-600"].join(" ")]: color === "blue",
        [["bg-red-500", "hover:bg-red-600"].join(" ")]: color === "red",
        [[
            "bg-transparent", "hover:text-grey-900", "hover:bg-grey-100", "text-grey-700",
            "dark:hover:text-grey-50", "dark:hover:bg-grey-800", "text-grey-900",
        ].join(" ")]: color === "transparent",
    }, className);
    return to ? (React.createElement(Link, Object.assign({ to: to, className: classes }, props), children)) : (React.createElement("button", Object.assign({ className: classes }, props), children));
}
