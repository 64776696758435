import { createSlice } from "@reduxjs/toolkit";
import { getCompanies, getCompany, setCompanies, setCompany, setInitialized } from "./actions";
export * from "./actions";
const initialState = {
    initialized: false,
    collection: {},
    ids: [],
};
const extraReducers = {
    [setCompanies.type]: (state, { payload }) => {
        const { collection, ids } = (payload || []).reduce((map, company) => {
            map.collection[company.id] = company;
            map.ids.push(company.id);
            return map;
        }, { collection: {}, ids: [] });
        state.collection = collection;
        state.ids = ids;
    },
    [setCompany.type]: (state, { payload }) => {
        state.ids = Array.from(new Set([...state.ids, payload.id]));
        state.collection[payload.id] = payload;
    },
    [setInitialized.type]: (state, { payload }) => {
        state.initialized = payload;
    },
    [getCompanies.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
    [getCompany.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
};
export const companiesSlice = createSlice({
    name: "companies",
    initialState,
    extraReducers,
    reducers: {},
});
// Action creators are generated for each case reducer function
// export const {
//   // @TODO
// } = companiesSlice.actions
export default companiesSlice.reducer;
