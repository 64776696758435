export var UserRole;
(function (UserRole) {
    // работник, привязанный к организации, может генерировать отчеты
    UserRole["Booker"] = "booker";
    // владелец, тип аккаунта управляющего организациями
    UserRole["Owner"] = "owner";
    // модератор, имеет возможность подписывать документы и редактировать параметры организации
    UserRole["Moderator"] = "moderator";
    // гость, пользователь без компаний, либо отвязанный от компании
    UserRole["Guest"] = "guest";
})(UserRole || (UserRole = {}));
