import { Link, useMatch, useResolvedPath } from "react-router-dom";
import React from "react";
import cx from "clsx";
export function Item({ title, icon: Icon, to, onClick }) {
    let match;
    if (to) {
        const resolved = useResolvedPath(to);
        match = useMatch({ path: resolved.pathname, end: true });
    }
    const itemClasses = cx({
        "font-semibold bg-grey-200 text-grey-900 dark:bg-grey-800 dark:text-grey-100": !!match,
        "font-medium text-grey-700 dark:text-grey-500": !match,
    }, "flex items-center", "rounded", "p-2", "hover:bg-grey-200 dark:hover:bg-grey-800", "transition duration-300", "text-base");
    // prettier-ignore
    const iconClasses = cx("h-6 w-6", "transition duration-75");
    const content = (React.createElement(React.Fragment, null,
        Icon && React.createElement(Icon, { className: iconClasses }),
        React.createElement("span", { className: "ml-3" }, title)));
    return (React.createElement("li", null, onClick ? (React.createElement("div", { className: itemClasses, onClick: onClick }, content)) : (React.createElement(Link, { className: itemClasses, to: to }, content))));
}
