import { createSlice } from "@reduxjs/toolkit";
export * from "./actions";
import { unsetContractsByContractor, setInitialized, unsetContract, getContracts, setContracts, getContract, setContract, } from "./actions";
const initialState = {
    initialized: false,
    byCompaniesIds: {},
    byCompanies: {},
    byContractorsIds: {},
    byContractors: {},
    collection: {},
    ids: [],
};
const extraReducers = {
    [setContracts.type]: (state, { payload: { companyId, contractorId, contracts } }) => {
        for (const contract of contracts) {
            state.byContractors[contract.id] = contractorId;
            state.byCompanies[contract.id] = companyId;
            state.collection[contract.id] = contract;
            state.ids.push(contract.id);
            if (!state.byCompaniesIds[companyId]) {
                state.byCompaniesIds[companyId] = [];
            }
            if (!state.byContractorsIds[contractorId]) {
                state.byContractorsIds[contractorId] = [];
            }
            // prettier-ignore
            state.byCompaniesIds[companyId] = [...new Set([
                    ...state.byCompaniesIds[companyId],
                    contract.id
                ])].sort();
            // prettier-ignore
            state.byContractorsIds[contractorId] = [...new Set([
                    ...state.byContractorsIds[contractorId],
                    contract.id
                ])].sort();
        }
        state.ids = [...new Set([...state.ids])].sort();
    },
    [setContract.type]: (state, { payload: { companyId, contractorId, contract } }) => {
        state.byContractors[contract.id] = contractorId;
        state.byCompanies[contract.id] = companyId;
        state.collection[contract.id] = contract;
        state.ids.push(contract.id);
        if (!state.byCompaniesIds[companyId]) {
            state.byCompaniesIds[companyId] = [];
        }
        if (!state.byContractorsIds[contractorId]) {
            state.byContractorsIds[contractorId] = [];
        }
        // prettier-ignore
        state.byCompaniesIds[companyId] = [...new Set([
                ...state.byCompaniesIds[companyId],
                contract.id
            ])].sort();
        // prettier-ignore
        state.byContractorsIds[contractorId] = [...new Set([
                ...state.byContractorsIds[contractorId],
                contract.id
            ])].sort();
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [unsetContract.type]: (state, { payload: id }) => {
        const contractorId = state.byContractors[id];
        const companyId = state.byCompanies[id];
        state.byContractorsIds[contractorId] = state.byContractorsIds[contractorId].filter((_id) => id !== _id);
        state.byCompaniesIds[companyId] = state.byCompaniesIds[companyId].filter((_id) => id !== _id);
        delete state.byContractors[id];
        delete state.byCompanies[id];
        delete state.collection[id];
        state.ids.splice(state.ids.indexOf(id), 1);
    },
    [unsetContractsByContractor.type]: (state, { payload: contractorId }) => {
        const ids = state.byContractorsIds[contractorId];
        if (!ids) {
            return;
        }
        for (const id of ids) {
            const companyId = state.byCompanies[id];
            state.byCompaniesIds[companyId] = state.byCompaniesIds[companyId].filter((_id) => id !== _id);
            delete state.byContractors[id];
            delete state.byCompanies[id];
            delete state.collection[id];
            state.ids.splice(state.ids.indexOf(id), 1);
        }
        delete state.byContractorsIds[contractorId];
    },
    [setInitialized.type]: (state, { payload }) => {
        state.initialized = payload;
    },
    [getContracts.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
    [getContract.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
};
export const contractsSlice = createSlice({
    name: "contracts",
    initialState,
    extraReducers,
    reducers: {},
});
// Action creators are generated for each case reducer function
// export const {
//   // @TODO
// } = contractsSlice.actions
export default contractsSlice.reducer;
