var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { i18n } from "@lingui/core";
import * as Yup from "yup";
import cx from "clsx";
import { FieldInput } from "../../containers/Forms/Builder";
import { Alert, Button, Spinner } from "../../components";
import { updatePassword } from "../../features/session";
const schema = Yup.object({
    old_password: Yup.string().min(6).required(),
    new_password: Yup.string().min(6).required(),
}).required();
export function UpdatePasswordForm({ className }) {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = useCallback((form) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            // prettier-ignore
            const action = updatePassword(form);
            yield dispatch(action).unwrap();
            reset();
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2500);
        }
        finally {
            setLoading(false);
        }
    }), []);
    // prettier-ignore
    return (React.createElement("form", { className: cx("form relative flex flex-col items-center py-6", className), onSubmit: handleSubmit(onSubmit) },
        React.createElement("h2", { className: "font-semibold text-xl text-left text-stone-800 dark:text-grey-50" }, i18n._("profile_change_password")),
        loading && (React.createElement("div", { className: "z-50 flex items-center justify-center absolute top-0 right-0 left-0 bottom-0", style: { background: "rgba(255,255,255, 0.3)" } },
            React.createElement(Spinner, null))),
        React.createElement("div", { className: cx("fields flex flex-col w-full", { "blur-sm": loading }) },
            React.createElement(FieldInput, { placeholder: "profile__old_password", validator: register, error: errors.old_password, className: "mt-4", name: "old_password", type: "password" }),
            React.createElement(FieldInput, { placeholder: "profile__new_password", validator: register, error: errors.new_password, className: "mt-4", name: "new_password", type: "password" })),
        error && (React.createElement(Alert, { fill: true, type: "error", className: "mt-5" }, i18n._(error))),
        React.createElement(Button, { color: error ? "red" : "blue", type: "submit", className: cx("mt-4", { "blur-sm": loading }) }, i18n._("profile_password_update_action"))));
}
