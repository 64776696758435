export var SalaryType;
(function (SalaryType) {
    SalaryType["Month"] = "month";
    SalaryType["Hour"] = "hour";
})(SalaryType || (SalaryType = {}));
export var SalaryCurrency;
(function (SalaryCurrency) {
    SalaryCurrency["Eur"] = "eur";
    SalaryCurrency["Usd"] = "usd";
    SalaryCurrency["Rub"] = "rub";
})(SalaryCurrency || (SalaryCurrency = {}));
export var Country;
(function (Country) {
    Country["Kazakhstan"] = "kazakhstan";
    Country["Serbia"] = "serbia";
    Country["Slovenia"] = "slovenia";
    Country["Belarus"] = "belarus";
    Country["Germany"] = "germany";
    Country["Russia"] = "russia";
    Country["USA"] = "usa";
})(Country || (Country = {}));
export var ContractType;
(function (ContractType) {
    // Самозанятый
    ContractType["SelfEmployed"] = "self_employed";
    // Индивидуальный предприниматель
    ContractType["SoleProprietor"] = "sole_proprietor";
})(ContractType || (ContractType = {}));
export var ReportRangeType;
(function (ReportRangeType) {
    ReportRangeType["Hours"] = "hours";
    ReportRangeType["Days"] = "days";
})(ReportRangeType || (ReportRangeType = {}));
export var AdittionalServiceType;
(function (AdittionalServiceType) {
    AdittionalServiceType["Subtract"] = "subtract";
    AdittionalServiceType["Add"] = "add";
})(AdittionalServiceType || (AdittionalServiceType = {}));
