var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import cx from "clsx";
export function Toggle(_a) {
    var { validator, error, name, label, className } = _a, props = __rest(_a, ["validator", "error", "name", "label", "className"]);
    const classes = cx("rounded-full", "toggle-bg", "border", "w-11", "h-6", {
        "focus:ring-blue-300 border-grey-300 bg-grey-50 dark:bg-grey-800 dark:border-grey-800": !error,
        "focus:ring-red-300 border-red-300 bg-red-50": error,
    });
    const classesLabel = cx("select-none", "font-medium", "text-sm", "ml-3", {
        "text-grey-900 dark:text-grey-50": !error,
        "text-red-900": error,
    });
    const inputProps = Object.assign({}, props, validator && validator(name));
    return (React.createElement("label", { htmlFor: name, className: "flex relative items-center cursor-pointer" },
        React.createElement("input", Object.assign({ type: "checkbox", id: name, className: "sr-only" }, inputProps)),
        React.createElement("div", { className: classes }),
        label && React.createElement("span", { className: classesLabel }, label)));
}
