import { i18n } from "@lingui/core";
import React from "react";
import { PageTitle } from "../PageTitle";
import { Button } from "../Button";
import * as Icons from "../Icons";
export function PageMenu({ actions, title }) {
    return (React.createElement("div", { className: "relative flex items-center justify-between overflow-hidden px-4 h-12 border-b bg-white dark:bg-grey-900 border-b-grey-200 dark:border-b-grey-800" },
        React.createElement("div", { className: "flex flex-row z-10" },
            React.createElement(Button, { to: "/dashboard", color: "transparent", className: "p-2" },
                React.createElement(Icons.ChevronLeft, { className: "h-4 w-4" }),
                React.createElement("span", { className: "ml-1" }, i18n._("sidebar__companies")))),
        React.createElement("div", { className: "absolute z-0 w-full text-center" },
            React.createElement("h4", { className: "font-semibold text-lg text-grey-900 dark:text-grey-50" }, title),
            React.createElement(PageTitle, null, title)),
        React.createElement("div", { className: "flex flex-row z-10" }, actions &&
            actions.map(({ title, icon: Icon, onClick, color = "transparent" }, index) => (React.createElement(Button, { key: index + title, color: color, className: "p-2", onClick: onClick },
                Icon && React.createElement(Icon, { className: "h-4 w-4" }),
                React.createElement("span", { className: "ml-1" }, title)))))));
}
