import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as selectors from "../../selectors";
import { Sidebar } from "../../containers";
export function Layout({ type, companyId, children }) {
    const session = useSelector(selectors.getSession);
    const navigate = useNavigate();
    useEffect(() => {
        if (!session.active && session.initialized && !session.token) {
            navigate("/signin", { replace: true });
        }
    }, [session]);
    return (React.createElement("div", { className: "dashboard flex flex-row min-h-screen bg-grey-50 dark:bg-grey-900" },
        React.createElement(Sidebar, { type: type, companyId: companyId }),
        React.createElement("div", { className: "flex flex-1 flex-col bg-grey-50 dark:bg-grey-900" }, children)));
}
