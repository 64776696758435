var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { i18n } from "@lingui/core";
import cx from "clsx";
import { getProfessions } from "../../../features/professions";
import { Icons, Spinner } from "../../../components";
import * as selectors from "../../../selectors";
import { FieldSelect } from "../Builder";
export function SelectProfession({ placeholder, validator, name, error, className }) {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const professionIds = useSelector((state) => selectors.getProfessionIdsByCompany(state, id));
    const professions = useSelector(selectors.getProfessions);
    const getProfessionList = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active) {
            setLoading(true);
            try {
                yield dispatch(getProfessions(id)).unwrap();
            }
            finally {
                setLoading(false);
            }
        }
    }), [id, session]);
    // prettier-ignore
    useEffect(() => { getProfessionList(); }, [id, session]);
    return loading ? (React.createElement("div", { className: cx("flex items-center justify-center w-full rounded-lg border p-2.5 bg-slate-100 border-slate-100", className) },
        React.createElement(Spinner, { size: "small" }))) : (professionIds === null || professionIds === void 0 ? void 0 : professionIds.length) ? (React.createElement(FieldSelect, { placeholder: placeholder, className: className, validator: validator, error: error, name: name }, professionIds.map((id, index) => (React.createElement("option", { key: id + index, value: professions[id].id }, professions[id].name))))) : (React.createElement(Link, { to: `/company/${id}/professions`, className: cx("flex items-center justify-center w-full rounded-lg border p-2.5 bg-slate-100 border-slate-100 cursor-pointer", className) },
        React.createElement("span", { className: "text-sm font-medium text-stone-900 mr-3" }, i18n._("professions_empty_title")),
        React.createElement(Icons.Plus, null)));
}
