export default {
    signin_title: "Авторизация",
    // common
    email: "Email",
    password: "Пароль",
    name: "Имя",
    not_specified: "Не Указано",
    is_default: "По умолчанию",
    edit: "Редактировать",
    create: "Создать",
    add: "Добавить",
    // Auth
    link_signup: "Нет аккаунта?",
    link_signin: "Уже есть аккаунт?",
    link_recovery: "Восстановить аккаунт",
    action_signin: "Войти",
    action_signup: "Зарегистрироваться",
    accept_terms: "Я принимаю правила сервиса",
    // errors
    error__required: "Это обязательное поле",
    error__min: "Минимальная длина",
    auth_unexpected_error: "Внезапная ошибка, попробуйте еще",
    auth_invalid_credentials: "Неверные данные",
    toast_create_success: "Успешно создано",
    toast_create_failed: "Ошибка при создании",
    toast_update_success: "Обновлено успешно",
    toast_update_failed: "Ошибка при обновлении",
    toast_delete_success: "Удалено успешно",
    toast_delete_failed: "Ошибка при удалении",
    toast_generate_docs_success: "Документы сгенерированы успешно, и отправлены на email-ы",
    toast_generate_docs_failed: "Ошибка при генерации документов",
    // Profile
    profile_title: "Профиль",
    profile_update_profile: "Детали",
    profile__name: "Полное Имя",
    profile_update_action: "Обновить Информацию",
    profile_change_password: "Обновить Пароль",
    profile__old_password: "Текущий Пароль",
    profile__new_password: "Новый Пароль",
    profile_password_update_action: "Изменить пароль",
    // Settings
    settings_title: "Настройки",
    settings__enable_dark: "Включить темную тему",
    // Companies List
    companies_title: "Компании",
    companies_empty_title: "У вас нет компаний",
    companies_empty_action: "Создать первую",
    companies_create_action: "Создать коммпанию",
    companies_update_action: "Обновить компанию",
    sidebar__companies: "Компании",
    sidebar__settings: "Настройки",
    sidebar__company: "Детали Компании",
    sidebar__bank_detail: "Банки",
    sidebar__professions: "Профессии",
    sidebar__contractors: "Контракторы",
    sidebar__contracts: "Контракты",
    sidebar__reports: "Отчеты",
    sidebar__report_groups: "Группы Отчетов",
    sidebar__logout: "Выйти",
    // Company
    company__representative: "Представитель",
    company__name: "Название компании",
    company__requisites: "Полные реквезиты",
    company__registration: "Страна",
    company__signature: "Подпись/Печать",
    company__notify_email: "Отправлять копии отчетов:",
    company__slovenia: "Словения",
    company__belarus: "Беларусь",
    company__germany: "Германия",
    company__russia: "Россия",
    company__usa: "Соединенные Штаты Америки",
    company__kazakhstan: "Казахстан",
    company__serbia: "Сербия",
    company_upload_signature: "Подпись компании",
    company_upload_signature_placeholder: "Перетащите подпись или выберите файл. Принимается файлы jpeg, png.",
    // Banks
    banks_empty_title: "Нет банковских реквезитов",
    bank__name: "Название",
    bank__detail: "Детали/Реквезиты",
    banks_update_action: "Обновить банк",
    banks_create_action: "Добавить банк",
    // Professions
    professions_empty_title: "Нет профессий",
    profession__name: "Название",
    profession__service: "Услуга",
    profession__grade: "Позиция",
    professions_update_action: "Обновить профессию",
    professions_create_action: "Добавить профессию",
    // Contractors
    contractors_empty_title: "Нет контракторов",
    contractors_update_action: "Обновить контрактора",
    contractors_create_action: "Создать контрактора",
    contractor__firstname: "Имя",
    contractor__surname: "Фамилия",
    contractor__patronymic: "Отчество",
    contractor__email: "Email",
    contractor__phone: "Телефон",
    contractor__citizenship: "Гражданство",
    contractor__enabled: "Пользователь активен",
    contractor__bank_detail: "Банковские реквезиты",
    contractor__address: "Адрес",
    contractor__profession: "Профессия",
    contractor: "Контрактор",
    // Contracts
    contracts_empty_title: "Contracts list is empty",
    contracts_update_action: "Update contract",
    contracts_create_action: "Create contract",
    contract__number: "Номер контракта",
    contract__self_employed: "Самозанятый",
    contract__sole_proprietor: "Индивидуальный предприниматель",
    contract__is_active: "Активен",
    contract__signed: "Подписан",
    company__salary__currency: "Валюта",
    company__salary__type: "Тип выплаты",
    contract__salary__hour: "Час",
    contract__salary__month: "Месяц",
    contract__salary__amount: "Сумма выплат",
    contract__salary: "Оплата",
    contract__started_date: "Дата начала (DD.MM.YYYY)",
    contract__ended_date: "Дата завершения (DD.MM.YYYY)",
    // Report group
    report_group__range__start: "Период с",
    report_group__range__end: "Период до",
    report_groups_create_action: "Создать новую группу отчетов",
    report_groups_empty_title: "Группа отчетов пуста",
    // Report
    report_range_type: "Тип периода отчета",
    report_range_hours: "Часы",
    report_range_days: "Дни",
    reports_empty_title: "Список отчетов пуст",
    report_working_days: "Всего рабочих дней в периоде",
    report_value: "Итоговое время работы в периоде (по типу периода)",
    report_update_action: "Пересчитать отчет",
    report_create_action: "Создать отчет",
    report_invoice: "Invoice",
    report_cert: "Cert",
};
