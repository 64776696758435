var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { i18n } from "@lingui/core";
import * as Yup from "yup";
import cx from "clsx";
import { createCompany, updateCompany } from "../../../features/companies";
import { Alert, Button, Spinner } from "../../../components";
import { FieldInput, FieldSelect } from "../Builder";
import { Country } from "../../../models";
const schema = Yup.object({
    registration: Yup.mixed().oneOf(Object.values(Country)).required(),
    name: Yup.string().min(5).required(),
    representative: Yup.string().min(5).optional(),
    requisites: Yup.string().min(5).optional(),
}).required();
export function CompanyForm({ company, onSaved }) {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: Object.assign({
            registration: Country.Slovenia,
        }, company && {
            representative: company.representative,
            registration: company.registration,
            requisites: company.requisites,
            name: company.name,
        })
    });
    const onSubmit = useCallback((form) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            // prettier-ignore
            const action = company
                ? updateCompany({ id: company.id, company: form })
                : createCompany(form);
            yield dispatch(action).unwrap();
            onSaved === null || onSaved === void 0 ? void 0 : onSaved(form);
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2500);
        }
        finally {
            setLoading(false);
        }
    }), []);
    // prettier-ignore
    return (React.createElement("form", { className: "form relative flex flex-col items-center", onSubmit: handleSubmit(onSubmit) },
        loading && (React.createElement("div", { className: "z-50 flex items-center justify-center absolute top-0 right-0 left-0 bottom-0", style: { background: "rgba(255,255,255, 0.3)" } },
            React.createElement(Spinner, null))),
        React.createElement("div", { className: cx("fields flex flex-col w-full", { "blur-sm": loading }) },
            React.createElement(FieldInput, { placeholder: "company__name", validator: register, error: errors.name, className: "mt-4", name: "name" }),
            React.createElement(FieldInput, { placeholder: "company__representative", error: errors.representative, name: "representative", validator: register, className: "mt-4" }),
            React.createElement(FieldInput, { placeholder: "company__requisites", error: errors.requisites, validator: register, name: "requisites", className: "mt-4", isArea: true }),
            React.createElement(FieldSelect, { placeholder: "company__registration", error: errors.registration, validator: register, name: "registration", className: "mt-4" },
                React.createElement("option", { value: "kazakhstan" }, i18n._("company__kazakhstan")),
                React.createElement("option", { value: "serbia" }, i18n._("company__serbia")),
                React.createElement("option", { value: "slovenia" }, i18n._("company__slovenia")),
                React.createElement("option", { value: "belarus" }, i18n._("company__belarus")),
                React.createElement("option", { value: "germany" }, i18n._("company__germany")),
                React.createElement("option", { value: "russia" }, i18n._("company__russia")),
                React.createElement("option", { value: "usa" }, i18n._("company__usa")))),
        error && (React.createElement(Alert, { fill: true, type: "error", className: "mt-5" }, i18n._(error))),
        React.createElement(Button, { color: error ? "red" : "blue", type: "submit", className: cx("mt-4", { "blur-sm": loading }) }, i18n._(company ? "companies_update_action" : "companies_create_action"))));
}
