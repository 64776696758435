var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { i18n } from "@lingui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { FieldInput } from "../../../containers/Forms/Builder";
import { Button, Icons, Spinner } from "../../../components";
import { updateCompany } from "../../../features/companies";
import * as selectors from "../../../selectors";
const schema = Yup.object({
    email: Yup.string().email().required(),
}).required();
export function NotifyEmails() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [emails, setEmails] = useState([]);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const { id } = useParams();
    const company = useSelector((state) => selectors.getCompany(state, id));
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const getOne = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active && company) {
            try {
                setEmails((company === null || company === void 0 ? void 0 : company.notification_emails) || []);
            }
            finally {
                setLoading(false);
            }
        }
    }), [company, session, company]);
    const onSubmit = useCallback((form) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        // Dont handle if already sending
        if (loading) {
            return;
        }
        try {
            // prettier-ignore
            const action = updateCompany({ id: company.id, company: { notification_emails: [...emails, form.email] } });
            yield dispatch(action).unwrap();
            reset();
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2000);
        }
        finally {
            setLoading(false);
        }
    }), [emails, company, loading]);
    const onDeleteEmail = useCallback((index) => () => __awaiter(this, void 0, void 0, function* () {
        const updated = [...emails];
        updated.splice(index, 1);
        try {
            // prettier-ignore
            const action = updateCompany({ id: company.id, company: { notification_emails: updated } });
            yield dispatch(action).unwrap();
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2000);
        }
        finally {
            setLoading(false);
        }
    }), [emails]);
    // prettier-ignore
    useEffect(() => { getOne(); }, [session, company]);
    return (React.createElement("div", { className: "flex flex-col" }, loading || !company ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
        React.createElement(Spinner, null))) : (React.createElement("div", { className: "flex flex-col p-4" },
        React.createElement("h4", { className: "font-semibold text-lg mb-4 text-grey-900 dark:text-grey-50" }, i18n._("company__notify_email")),
        React.createElement("div", { className: "flex flex-row" },
            React.createElement("ul", { className: "flex-1 flex flex-wrap items-start justify-start border-dashed border-2 p-1 border-gray-200 rounded-lg" }, emails.map((email, index) => (React.createElement("li", { className: "flex py-1 px-2 mr-2 border rounded-full hover:border-grey-100", key: email + index },
                React.createElement("span", { className: "text-md font-medium text-grey-900 dark:text-grey-50" }, email),
                React.createElement("div", { onClick: onDeleteEmail(index), className: "ml-3 h-6 w-6 rounded-full border border-grey-100 flex items-center justify-center cursor-pointer" },
                    React.createElement(Icons.Delete, { className: "h-4 w-4 text-grey-900 dark:text-grey-50" })))))),
            React.createElement("form", { className: "fields flex flex-row m-4", onSubmit: handleSubmit(onSubmit) },
                React.createElement(FieldInput, { inputClassName: "rounded-r-none", placeholder: "email", error: errors.email, name: "email", validator: register }),
                React.createElement(Button, { className: "rounded-l-none", color: error ? "red" : "blue", type: "submit" }, loading ? React.createElement(Spinner, null) : i18n._("add"))))))));
}
