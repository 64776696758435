var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState } from "react";
import { i18n } from "@lingui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Button, Icons } from "../../../components";
import { FieldInput } from "../Builder";
const schema = Yup.object({
    value: Yup.number().required(),
    name: Yup.string().required(),
}).required();
export function AdditionalServices(props) {
    const [services, setServices] = useState(props.services);
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = useCallback((_form) => __awaiter(this, void 0, void 0, function* () {
        const form = Object.assign(Object.assign({}, _form), { value: _form.value * 100 });
        const _services = [...services, form];
        setServices(_services);
        props.onChange(_services);
        reset();
    }), [services]);
    const onDeleteEmail = useCallback((index) => () => __awaiter(this, void 0, void 0, function* () {
        const _services = [...services];
        _services.splice(index, 1);
        setServices(_services);
        props.onChange(_services);
    }), [services]);
    return (React.createElement("div", { className: "flex flex-col mt-5" },
        React.createElement("div", { className: "flex flex-col rounded-lg" },
            React.createElement("ul", { className: "h-24 overflow-y-scroll flex flex-col border-dashed border-2 p-1 border-gray-200 rounded-lg" }, services.map(({ name, value }, index) => (React.createElement("li", { className: "flex flex-row items-center py-1 px-2", onClick: onDeleteEmail(index), key: name + index },
                React.createElement("span", { className: "text-md flex-1 font-medium text-stone-600" }, name),
                React.createElement("span", { className: "text-md w-20 font-medium text-stone-900" }, value / 100),
                React.createElement("div", { className: "ml-3 h-6 w-6 rounded-full border border-stone-100 flex items-center justify-center cursor-pointer" },
                    React.createElement(Icons.Delete, { className: "h-4 w-4" })))))),
            React.createElement("form", { className: "flex flex-row mt-4", onSubmit: handleSubmit(onSubmit) },
                React.createElement(FieldInput, { validator: register, error: errors.name, placeholder: "add_service_name", className: "flex-1 mr-2", name: "name" }),
                React.createElement(FieldInput, { validator: register, error: errors.value, placeholder: "add_service_value", className: "w-20 mr-2", name: "value" }),
                React.createElement(Button, { color: "blue", type: "submit" }, i18n._("add"))))));
}
