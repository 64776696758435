var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from "react";
import { i18n } from "@lingui/core";
import dayjs from "dayjs";
import { Button, Icons } from "../../../../components";
export function ContractRow(_a) {
    var { onEdit, onDelete } = _a, contract = __rest(_a, ["onEdit", "onDelete"]);
    const onClickDelete = useCallback(() => {
        onDelete === null || onDelete === void 0 ? void 0 : onDelete(contract);
    }, [onDelete, contract]);
    const onClickEdit = useCallback(() => {
        onEdit === null || onEdit === void 0 ? void 0 : onEdit(contract);
    }, [onEdit, contract]);
    return (React.createElement("div", { className: "flex flex-row items-center relative px-4 py-2 w-full border-b border-grey-100 dark:border-grey-700" },
        React.createElement("div", { className: "flex flex-col w-60" },
            React.createElement("span", { className: "text-md font-medium text-grey-900 dark:text-grey-50" },
                "\u2116 ",
                contract.id,
                " (",
                i18n._(`contract__${contract.contract_type}`),
                ")"),
            React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-500" },
                contract.salary.amount / 100,
                " ",
                contract.salary.currency,
                " per",
                " ",
                i18n._(`contract__salary__${contract.salary.type}`))),
        React.createElement("div", { className: "flex flex-col flex-1" },
            React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-500" },
                dayjs(contract.started_date).format("DD.MM.YYYY"),
                " ",
                contract.ended_date && React.createElement(React.Fragment, null,
                    "- ",
                    dayjs(contract.ended_date).format("DD.MM.YYYY")))),
        React.createElement("div", { className: "flex flex-row" },
            React.createElement(Button, { className: "h-8 w-8 p-0 flex items-center justify-center mr-3", onClick: onClickEdit, color: "transparent", type: "button" },
                React.createElement(Icons.Edit, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-300" })),
            React.createElement(Button, { className: "h-8 w-8 p-0 flex items-center justify-center", onClick: onClickDelete, color: "transparent", type: "button" },
                React.createElement(Icons.Delete, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-300" })))));
}
