var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import cx from "clsx";
export function Input(_a) {
    var { error, validator, className, isArea } = _a, props = __rest(_a, ["error", "validator", "className", "isArea"]);
    const classes = cx("placeholder:font-medium", "font-medium", "rounded", "text-sm", "border", "block", "w-full", "p-2.5", "focus:border-blue-200", "focus:outline-none", {
        "border-slate-100 bg-slate-100 dark:border-grey-800 dark:bg-grey-900": !error,
        "placeholder:text-grey-400 text-grey-900 dark:text-grey-50": !error,
        "border-red-100 bg-red-100": error,
        "placeholder:text-red-400 text-red-900": error,
    }, 
    // eslint-disable-next-line react/prop-types
    className);
    const inputProps = Object.assign({}, {
        className: classes,
    }, props, validator && validator(props.name));
    return isArea ? React.createElement("textarea", Object.assign({}, inputProps)) : React.createElement("input", Object.assign({}, inputProps));
}
