var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api";
import * as selectors from "../../selectors";
export const setInitialized = createAction("session/setInitialized");
export const setProfile = createAction("session/setProfile");
export const setToken = createAction("session/setToken");
export const logOut = createAction("session/logOut");
export const initSession = createAsyncThunk("session/init", (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    if (localStorage.token) {
        try {
            const { data } = yield api.getProfile(localStorage.token);
            dispatch(setToken(localStorage.token));
            dispatch(setProfile(data));
        }
        catch (error) {
            localStorage.removeItem("token");
            dispatch(setToken());
        }
    }
    dispatch(setInitialized(true));
}));
export const signIn = createAsyncThunk("session/signIn", (payload, { dispatch, rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data, meta } = yield api.signIn(payload);
        localStorage.setItem("token", meta.accessToken);
        dispatch(setToken(meta.accessToken));
        dispatch(setProfile(data));
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const signUp = createAsyncThunk("session/signUp", (payload, { dispatch, rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data, meta } = yield api.signUp(payload);
        localStorage.setItem("token", meta.accessToken);
        dispatch(setToken(meta.accessToken));
        dispatch(setProfile(data));
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const updateProfile = createAsyncThunk("session/updateProfile", (body, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data } = yield api.updateProfile(body, token);
            dispatch(setProfile(data));
        }
        catch (error) {
            // @TODO
        }
    }
}));
export const updatePassword = createAsyncThunk("session/updatePassword", (body, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const token = selectors.getToken(state);
    if (token) {
        try {
            // prettier-ignore
            const { data } = yield api.updatePassword(body, token);
            dispatch(setProfile(data));
        }
        catch (error) {
            // @TODO
        }
    }
}));
