var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import cx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Alert, Button, Input, Link, Spinner } from "../../components";
import { signIn } from "../../features/session";
const schema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required(),
}).required();
export function SignIn() {
    const session = useSelector((state) => state.session);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // prettier-ignore
    const { register, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        if (session.initialized && session.user) {
            navigate("/dashboard", { replace: true });
        }
    }, [session]);
    const onSubmit = useCallback((form) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield dispatch(signIn(form)).unwrap();
            navigate("/dashboard");
        }
        catch (error) {
            // prettier-ignore
            setError(error.status === 400
                ? "auth_invalid_credentials"
                : "auth_unexpected_error");
            setTimeout(() => setError(undefined), 2500);
        }
        finally {
            setLoading(false);
        }
    }), []);
    return (React.createElement("div", { className: "page signin h-screen flex items-center justify-center bg-grey-100 dark:bg-grey-800" },
        React.createElement("div", { className: cx("form relative rounded-md p-4 overflow-hidden bg-white dark:bg-grey-900", { loading }) },
            loading && (React.createElement("div", { className: "flex items-center justify-center absolute top-0 right-0 left-0 bottom-0 bg-semi-white dark:bg-semi-black" },
                React.createElement(Spinner, null))),
            React.createElement("h4", { className: "text-xl font-semibold text-center text-grey-900 dark:text-grey-50" },
                React.createElement(Trans, { id: "signin_title" })),
            React.createElement("form", { className: "fields flex flex-col m-4", onSubmit: handleSubmit(onSubmit) },
                React.createElement(Input, { name: "email", error: !!errors.email, placeholder: i18n._("email"), validator: register }),
                errors.email && (React.createElement("span", { className: "text-xs mt-1 text-medium text-red-600" }, i18n._(`error__${errors.email.type}`))),
                React.createElement(Input, { name: "password", type: "password", error: !!errors.password, className: "mt-4", placeholder: i18n._("password"), validator: register }),
                errors.password && (React.createElement("span", { className: "text-xs mt-1 text-medium text-red-600" }, i18n._(`error__${errors.password.type}`))),
                React.createElement("div", { className: "bottom flex flex-col items-center mt-6" },
                    React.createElement("span", { className: "signup-link text-sm text-grey-700 dark:text-grey-300" },
                        i18n._("link_signup"),
                        React.createElement(Link, { to: "/signup", className: "ml-1" }, i18n._("signup_title"))),
                    error && (React.createElement(Alert, { fill: true, type: "error", className: "mt-5" }, i18n._(error))),
                    React.createElement(Button, { color: error ? "red" : "blue", type: "submit", className: "mt-4 justify-center", fill: true }, i18n._("action_signin")),
                    React.createElement(Link, { to: "/recovery", className: "mt-5" }, i18n._("link_recovery")))))));
}
