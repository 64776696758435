import React from "react";
import cx from "clsx";
export function Checkbox({ validator, name, error, className, label }) {
    const classes = cx("form-checkbox", "rounded", "outline-none", "border dark:border-grey-700", "focus:ring-0 focus:ring-inherit focus:ring-transparent", "w-5", "h-5", {
        "border-grey-300 bg-grey-50 dark:bg-grey-800 dark:border-grey-600": !error,
        "border-red-300 bg-red-50": error,
    });
    const classesLabel = cx("font-medium", {
        "text-grey-900 dark:text-grey-50": !error,
        "text-red-900": error,
    });
    const inputProps = Object.assign({}, validator && validator(name));
    return (React.createElement("div", { className: cx("flex items-start", className) },
        React.createElement("div", { className: "flex items-center h-5" },
            React.createElement("input", Object.assign({ id: name, "aria-describedby": name, type: "checkbox", className: classes }, inputProps))),
        label && (React.createElement("div", { className: "ml-3 text-sm select-none" },
            React.createElement("label", { htmlFor: name, className: classesLabel }, label)))));
}
