var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import cx from "clsx";
export function Select(_a) {
    var { error, validator, className, children } = _a, props = __rest(_a, ["error", "validator", "className", "children"]);
    const classes = cx("font-medium", "rounded", "text-sm", "border", "block", "p-2.5", {
        "border-slate-100 text-grey-900 bg-grey-100 focus:border-grey-500 dark:border-grey-800 dark:text-grey-50 dark:bg-grey-900 dark:focus:border-grey-100": !error,
        "border-red-300 text-red-900 bg-red-50 focus:border-red-500": error,
    }, 
    // eslint-disable-next-line react/prop-types
    className);
    const selectProps = Object.assign({}, {
        className: classes,
    }, props, validator && validator(props.name));
    return React.createElement("select", Object.assign({}, selectProps), children);
}
