import { createSlice } from "@reduxjs/toolkit";
import { unsetReportGroup, getReportGroups, getReportGroup, setReportGroups, setReportGroup, setInitialized, } from "./actions";
export * from "./actions";
const initialState = {
    initialized: false,
    byCompaniesIds: {},
    byCompanies: {},
    collection: {},
    ids: [],
};
const extraReducers = {
    [setReportGroups.type]: (state, { payload: { companyId, reportGroups } }) => {
        for (const reportGroup of reportGroups) {
            state.byCompanies[reportGroup.id] = companyId;
            state.collection[reportGroup.id] = reportGroup;
            state.ids.push(reportGroup.id);
            if (!state.byCompaniesIds[companyId]) {
                state.byCompaniesIds[companyId] = [];
            }
            // prettier-ignore
            state.byCompaniesIds[companyId] = Array.from(new Set([
                ...state.byCompaniesIds[companyId],
                reportGroup.id
            ])).sort();
        }
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [setReportGroup.type]: (state, { payload: { companyId, reportGroup } }) => {
        state.byCompanies[reportGroup.id] = companyId;
        state.collection[reportGroup.id] = reportGroup;
        state.ids.push(reportGroup.id);
        if (!state.byCompaniesIds[companyId]) {
            state.byCompaniesIds[companyId] = [];
        }
        // prettier-ignore
        state.byCompaniesIds[companyId] = Array.from(new Set([
            ...state.byCompaniesIds[companyId],
            reportGroup.id
        ])).sort();
        state.ids = Array.from(new Set([...state.ids])).sort();
    },
    [unsetReportGroup.type]: (state, { payload: id }) => {
        const companyId = state.byCompanies[id];
        delete state.byCompanies[id];
        delete state.collection[id];
        state.byCompaniesIds[companyId] = state.byCompaniesIds[companyId].filter((_id) => id !== _id);
        state.ids = state.ids.filter((_id) => id !== _id);
    },
    [setInitialized.type]: (state, { payload }) => {
        state.initialized = payload;
    },
    [getReportGroups.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
    [getReportGroup.fulfilled.type]: (state, { payload }) => {
        // @TODO
    },
};
export const reportGroupsSlice = createSlice({
    name: "reportGroups",
    initialState,
    extraReducers,
    reducers: {},
});
// Action creators are generated for each case reducer function
// export const {
//   // @TODO
// } = reportGroupsSlice.actions
export default reportGroupsSlice.reducer;
