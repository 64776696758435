var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from "react";
import { i18n } from "@lingui/core";
import { Button, Icons } from "../../../components";
export function RowItem(_a) {
    var { onEdit, onDelete } = _a, contractor = __rest(_a, ["onEdit", "onDelete"]);
    const { firstname, surname, patronymic, email, phone, citizenship, company, id } = contractor;
    const onClickDelete = useCallback(() => {
        onDelete === null || onDelete === void 0 ? void 0 : onDelete(contractor);
    }, [onDelete, contractor]);
    const onClickEdit = useCallback(() => {
        onEdit === null || onEdit === void 0 ? void 0 : onEdit(contractor);
    }, [onEdit, contractor]);
    return (React.createElement("div", { className: "flex flex-row items-center relative px-8 py-2 w-full border-b border-grey-200 dark:border-grey-800" },
        React.createElement("div", { className: "flex flex-col w-60" },
            React.createElement("span", { className: "text-md font-medium text-grey-900 dark:text-grey-50" },
                firstname,
                " ",
                surname,
                " ",
                patronymic),
            React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-500" }, i18n._(`company__${citizenship}`))),
        React.createElement("div", { className: "flex flex-col flex-1" },
            React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-500" },
                React.createElement("span", { className: "text-grey-800 dark:text-grey-200" }, i18n._("contractor__email")),
                ":\u00A0 ",
                email),
            React.createElement("span", { className: "text-sm font-medium text-grey-600 dark:text-grey-500" },
                React.createElement("span", { className: "text-grey-800 dark:text-grey-200" }, i18n._("contractor__phone")),
                ": ",
                phone)),
        React.createElement(Button, { className: "h-8 w-8 p-0 flex items-center justify-center", onClick: onClickEdit, color: "transparent", type: "button" },
            React.createElement(Icons.Edit, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-100" })),
        React.createElement(Button, { className: "h-8 w-8 p-0 ml-2 mr-4 flex items-center justify-center", onClick: onClickDelete, color: "transparent", type: "button" },
            React.createElement(Icons.Delete, { className: "h-5 w-5 z-10 text-grey-800 dark:text-grey-100" })),
        React.createElement(Button, { className: "ml-5 h-8 w-8 p-0 flex items-center justify-center", to: `/company/${company}/contractors/${id}`, color: "transparent", type: "button" },
            React.createElement(Icons.ChevronLeft, { className: "h-5 w-5 z-10 rotate-180 text-grey-800 dark:text-grey-100" }))));
}
