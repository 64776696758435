export default {
    signin_title: "Sign In",
    signup_title: "Sign Up",
    // common
    password: "Password",
    email: "Email",
    name: "Name",
    not_specified: "Not Specified",
    is_default: "is default",
    create: "Create",
    edit: "Edit",
    add: "Add",
    // Auth
    link_signup: "Don't have account?",
    link_signin: "Already have account?",
    link_recovery: "Recovery account",
    action_signin: "Auth",
    action_signup: "Register",
    accept_terms: "I accept terms of service",
    // errors
    error__required: "Its required field",
    error__min: "Minumum length is",
    auth_unexpected_error: "Unexpected error, try later",
    auth_invalid_credentials: "Invalid credentials",
    // Toasts
    toast_create_success: "Created successfully",
    toast_create_failed: "Create failed",
    toast_update_success: "Updated successfully",
    toast_update_failed: "Update failed",
    toast_delete_success: "Deleted successfully",
    toast_delete_failed: "Delete failed",
    toast_generate_docs_success: "Documents generated success, and sended to emails",
    toast_generate_docs_failed: "Documents generate failed",
    // Profile
    profile_title: "Profile",
    profile_update_profile: "Details",
    profile__name: "Fullname",
    profile_update_action: "Update Info",
    profile_change_password: "Change Password",
    profile__old_password: "Current Password",
    profile__new_password: "New Password",
    profile_password_update_action: "Update",
    // Settings
    settings_title: "Settings",
    settings__enable_dark: "Enable dark theme",
    // Companies List
    companies_title: "Companies",
    companies_empty_title: "Mentioned companies list is empty",
    companies_empty_action: "Create fisrt",
    companies_create_action: "Create company",
    companies_update_action: "Update company",
    sidebar__companies: "Companies",
    sidebar__settings: "Settings",
    sidebar__company: "Company Details",
    sidebar__bank_detail: "Banks",
    sidebar__professions: "Professions",
    sidebar__contractors: "Contractors",
    sidebar__contracts: "Contracts",
    sidebar__reports: "Reports",
    sidebar__report_groups: "Report Groups",
    sidebar__logout: "Logout",
    // Company
    company__representative: "Representative",
    company__name: "Company name",
    company__requisites: "Full requisites",
    company__registration: "Country",
    company__signature: "Signature",
    company__notify_email: "Send copy of reports to:",
    company__slovenia: "Slovenia",
    company__belarus: "Belarus",
    company__kazakhstan: "Kazakhstan",
    company__serbia: "Serbia",
    company__germany: "Germany",
    company__russia: "Russia",
    company__usa: "USA",
    company_upload_signature: "Company Signature",
    company_upload_signature_placeholder: "Drop or select signature. Accept jpeg, png files.",
    // Banks
    banks_empty_title: "Banks list is empty",
    bank__name: "Name",
    bank__detail: "Details",
    banks_update_action: "Update bank",
    banks_create_action: "Create bank",
    // Professions
    professions_empty_title: "Professions list is empty",
    profession__name: "Name",
    profession__service: "Service",
    profession__grade: "Grade",
    professions_update_action: "Update profession",
    professions_create_action: "Create profession",
    // Contractors
    contractors_empty_title: "Contractors list is empty",
    contractors_update_action: "Update contractor",
    contractors_create_action: "Create contractor",
    contractor__firstname: "Firstname",
    contractor__surname: "Surname",
    contractor__patronymic: "Patronymic",
    contractor__email: "Email",
    contractor__phone: "Phone",
    contractor__citizenship: "Citizenship",
    contractor__enabled: "User is active",
    contractor__bank_detail: "Bank Detail",
    contractor__address: "Address",
    contractor__profession: "Profession",
    contractor: "Contractor",
    // Contracts
    contracts_empty_title: "Contracts list is empty",
    contracts_update_action: "Update contract",
    contracts_create_action: "Create contract",
    contract__number: "Contract number",
    contract__self_employed: "Self Employed",
    contract__sole_proprietor: "Sole proprietor",
    contract__is_active: "Is active",
    contract__signed: "Is Signed",
    contract__salary: "Salary",
    company__salary__currency: "Currency",
    company__salary__type: "Salary type",
    contract__salary__hour: "Hour",
    contract__salary__month: "Month",
    contract__salary__amount: "Salary amount",
    contract__started_date: "Started date (DD.MM.YYYY)",
    contract__ended_date: "Ended date (DD.MM.YYYY)",
    // Report group
    report_group__range__start: "Range from",
    report_group__range__end: "Range to",
    report_groups_create_action: "Create Report Group",
    report_groups_empty_title: "Report groups is empty",
    // Report
    report_range_type: "Report range type",
    report_range_hours: "Hours",
    reports_empty_title: "Reports is empty",
    report_range_days: "Days",
    report_working_days: "Total working days in period",
    report_value: "Total working time by contractor (by type)",
    report_update_action: "Recalculate Report",
    report_create_action: "Create Report",
    report_invoice: "Invoice",
    report_cert: "Cert",
};
