import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "@lingui/core";
import { ActionMessage, Button, Icons, Modal, PageMenu, Spinner } from "../../../components";
import { getCompanies } from "../../../features/companies";
import { CompanyForm } from "../../../containers";
import * as selectors from "../../../selectors";
import { RowItem } from "./RowItem";
export function CompaniesList() {
    const initialized = useSelector(selectors.getCompaniesInitialized);
    const companies = useSelector(selectors.getCompanies);
    const ids = useSelector(selectors.getCompaniesIds);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const [formOpen, setFormOpen] = useState(false);
    useEffect(() => {
        if (session.initialized) {
            if (session.active && !initialized) {
                dispatch(getCompanies());
            }
        }
    }, [initialized, session.initialized, session.active]);
    const onCloseModal = useCallback(() => setFormOpen(false), []);
    const onClickCreate = useCallback(() => setFormOpen(true), []);
    const list = ids.map(id => React.createElement(RowItem, { key: id, company: companies[id] }));
    const modalProps = {
        title: i18n._("companies_empty_action"),
        name: "company-create",
        onClose: onCloseModal,
        open: formOpen,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageMenu, { title: i18n._("companies_title") }),
        !initialized ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
            React.createElement(Spinner, null))) : ids.length ? (React.createElement(React.Fragment, null,
            list,
            React.createElement("div", { className: "flex items-center justify-center py-6 border-t border-grey-200 dark:border-grey-800" },
                React.createElement(Button, { className: "px-3 py-2", onClick: onClickCreate },
                    React.createElement(Icons.Plus, { className: "mr-1" }),
                    i18n._("companies_create_action"))))) : (React.createElement(ActionMessage, { title: i18n._("companies_empty_title"), action: i18n._("companies_empty_action"), className: "mx-20 mt-10", onClick: onClickCreate, icon: Icons.Plus })),
        formOpen && (React.createElement(Modal, Object.assign({}, modalProps),
            React.createElement("div", { className: "flex flex-col px-5 py-2" }, formOpen && React.createElement(CompanyForm, { onSaved: onCloseModal }))))));
}
