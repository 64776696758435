import { request } from "./common";
export function signIn(data) {
    return request("/auth/signin", "POST", {
        body: JSON.stringify(data),
    });
}
export function signUp(data) {
    return request("/auth/signup", "POST", {
        body: JSON.stringify(data),
    });
}
export function getProfile(token) {
    return request("/profile", "GET", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
export function updateProfile(body, token) {
    return request("/profile", "PATCH", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
export function updatePassword(body, token) {
    return request("/profile/change-password", "PATCH", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
}
