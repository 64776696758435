import React from "react";
import cx from "clsx";
export function Alert({ type = "default", className, children, fill }) {
    const classes = cx("rounded-lg", "text-sm", "p-4", {
        [["text-stone-700", "bg-stone-100"].join(" ")]: type === "default",
        [["text-red-700", "bg-red-100"].join(" ")]: type === "error",
        "w-full": fill,
    }, className);
    return (React.createElement("div", { className: classes, role: "alert" }, children));
}
