var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { i18n } from "@lingui/core";
import { ActionMessage, Icons, Modal, PageMenu, Spinner } from "../../../components";
import { getReportGroups } from "../../../features/reportGroups";
import { ReportGroupForm } from "../../../containers";
import * as selectors from "../../../selectors";
import { RowGroup } from "./Row";
export function CompanyReportGroups() {
    const [formOpen, setFormOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const session = useSelector(selectors.getSession);
    const dispatch = useDispatch();
    const { id } = useParams();
    const reportGroupIds = useSelector((state) => selectors.getReportGroupIdsByCompany(state, id));
    const company = useSelector((state) => selectors.getCompany(state, id));
    const reportGroups = useSelector(selectors.getReportGroups);
    const reports = useSelector(selectors.getReports);
    const getReportGroupsList = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (session && session.active) {
            setLoading(true);
            try {
                yield dispatch(getReportGroups(id)).unwrap();
            }
            finally {
                setLoading(false);
            }
        }
    }), [id, session]);
    const onOpenModal = useCallback(() => setFormOpen(true), []);
    // prettier-ignore
    useEffect(() => { getReportGroupsList(); }, [id, session]);
    const items = reportGroupIds &&
        reportGroupIds.map((groupId, index) => {
            const group = reportGroups[groupId];
            const _reports = group.reports.map(reportId => reports[reportId]);
            // prettier-ignore
            return React.createElement(RowGroup, Object.assign({ key: groupId + index }, { group, reports: _reports }));
        });
    const modalProps = {
        title: i18n._("sidebar__report_groups"),
        name: "reportGroups_editor",
        open: formOpen,
        onClose: () => {
            setFormOpen(false);
        },
    };
    const formProps = {
        onSaved: () => setFormOpen(false),
        companyId: company === null || company === void 0 ? void 0 : company.id,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageMenu, { title: i18n._("sidebar__report_groups"), actions: [
                {
                    icon: Icons.Plus,
                    title: i18n._("add"),
                    onClick: onOpenModal,
                },
            ] }),
        loading || !company ? (React.createElement("div", { className: "flex items-center justify-center p-10" },
            React.createElement(Spinner, null))) : !(reportGroupIds === null || reportGroupIds === void 0 ? void 0 : reportGroupIds.length) ? (React.createElement(ActionMessage, { title: i18n._("report_groups_empty_title"), action: i18n._("companies_empty_action"), className: "mx-20 mt-10", onClick: onOpenModal, icon: Icons.Plus })) : (React.createElement("div", { className: "flex flex-row flex-wrap" }, items)),
        formOpen && (React.createElement(Modal, Object.assign({}, modalProps),
            React.createElement("div", { className: "flex flex-col px-5 py-2" }, formOpen && React.createElement(ReportGroupForm, Object.assign({}, formProps)))))));
}
